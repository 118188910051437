import React, {useEffect, useState} from "react";
import {DATA_DEFAULTVALUES} from "../constants";
import {sendRequestToCMS} from "../utils";
import FaqItemComponent from "../components/FaqItemComponent";
import {LoadingComponent} from "../components/layout/LoadingComponent";
import sal from "sal.js";
import {motion} from "framer-motion";
import {useMouseMoveUI} from "../providers/MouseMoveProvider";

export const FaqSection = (props) => {
    const {mouseDirection, mouseReverse} = useMouseMoveUI();
    const [FAQs, setFAQs] = useState(DATA_DEFAULTVALUES.NULL);

    useEffect(() => {
        sendRequestToCMS(`/faqs?filters[faqTopic][$eq]=${props.faqTopic}`, setFAQs);
    }, []);

    useEffect(() => {
        sal();
    }, [FAQs]);

    if (!FAQs || FAQs === DATA_DEFAULTVALUES.NULL)
        return;
    else if (FAQs.data.length > 0) {
        sal();
        return (
            <div className={"mt-2"}>
                <div className="cta-area-1">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8">
                                <div className="home-four-cta edu-cta-box cta-style-3 faqTitleBG">
                                    <h2 className='whiteBlack'>Frequently Asked Questions</h2>
                                    <ul className="shape-group">
                                        <motion.li className="shape-01 scene"
                                                   transition={{duration: 0}} animate={{
                                            x: mouseReverse(25).x,
                                            y: mouseReverse(25).y
                                        }}
                                        >
                                            <img src={require("../assets/images/shapes/floatie-049.png")}
                                                 alt="shape"/>
                                        </motion.li>
                                        <motion.li className="shape-02 scene"
                                                   transition={{duration: 0}} animate={{
                                            x: mouseDirection(25).x,
                                            y: mouseDirection(25).y
                                        }}
                                        >
                                            <img src={require("../assets/images/shapes/floatie-102.png")}
                                                 alt="shape"/>
                                        </motion.li>
                                        <motion.li className="shape-03 scene"
                                                   transition={{duration: 0}} animate={{
                                            x: mouseDirection(25).x,
                                            y: mouseDirection(25).y
                                        }}
                                        >
                                            <img src={require("../assets/images/shapes/floatie-031.png")}
                                                 alt="shape"/>
                                        </motion.li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="edu-section-gap faq-page-area">
                    <div className="container">
                        <div className="row">
                            <div className="faq-accordion" id={"faq-accordion"}>
                                <div className="accordion" data-sal="slide-up" data-sal-duration="800">
                                    {!FAQs?.data && <LoadingComponent/>}
                                    {FAQs?.data.map((item, index) =>
                                        <FaqItemComponent show={index === 0} id={index} title={item.attributes.question}
                                                          desc={item.attributes.answer}/>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br/>
            </div>
        )
    }
}