import { useTypewriter, Cursor } from 'react-simple-typewriter';
import React, {useEffect, useState, useRef} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../../providers/MouseMoveProvider';

export const TypingTextSection = () => {
    const { mouseDirection, mouseReverse } = useMouseMoveUI();

    const [text] = useTypewriter({
        words: ['find all information in one platform', 
            'never pay charges for application processing', 
            'submit applications in a few clicks',
            'get reliable advice from international students'],
        loop:{},
        typeSpeed: 30,
        deleteSpeed: 20,
        delaySpeed: 3000,
    });

    return (
        <div className="container fluid" style={{paddingTop:'0px',paddingBottom:"50px"}}>
            <div className="row edublink-animated-shape typing-text-container">
                <div className='col-lg-6 p-0 m-0 typing-text-container'>
                    <h2>Become a part of Applystart community to {text}
                        <span><Cursor cursorStyle='|'/></span>
                    </h2>
                </div>
                <div className='col-lg-6 p-0 m-0'>
                    <img src={require("../../assets/images/views/landingView/heroCarousel01.jpg")}/>
                </div>
                <ul className="shape-group">
                    <motion.li className="shape-1 scene"
                        style={{top:"50px",left:"-150px", zIndex:"-10",opacity:"0.5"}} transition={{duration: 0}} animate={ {
                            x: mouseDirection(30).x,
                            y: mouseDirection(30).y
                        } }
                    >
                        <img src={require("../../assets/images/shapes/floatie-042.png")} alt="Shape" />
                    </motion.li>
                    <motion.li className="shape-3 scene"
                        style={{top:"-70px",left:"-50px"}} transition={{duration: 0}} animate={ {
                            x: mouseReverse(25).x,
                            y: mouseReverse(25).y
                        } }
                    >
                        <img src={require("../../assets/images/shapes/floatie-003.png")} alt="Shape" />
                    </motion.li>
                    <motion.li className="shape-2 scene"
                        style={{top:"50px",left:"1050px",scale:"1.5",opacity:"0.5", zIndex:"-50 !important"}} transition={{duration: 0}} animate={ {
                            x: mouseReverse(25).x,
                            y: mouseReverse(25).y
                        } }
                    >
                        <img src={require("../../assets/images/shapes/floatie-085.png")} alt="Shape" />
                    </motion.li>
                    <motion.li className="shape-6 scene"
                        style={{top:"-20px",left:"-50px", scale:"0.8", zIndex:"-50 !important"}} transition={{duration: 0}} animate={ {
                            x: mouseDirection(30).x,
                            y: mouseReverse(30).y
                        } }
                    >
                        <img src={require("../../assets/images/shapes/floatie-064.png")} alt="Shape" />
                    </motion.li>
                </ul>
            {/* <Swiper
                data-sal-delay="100" data-sal="slide-left" data-sal-duration="1000"
                className="typing-swiper"
                slidesPerView={'auto'}
                modules={[Autoplay]}
                spaceBetween={0}
                loop={true} // Ensure 'loop' is set correctly
                grabCursor={false}
                speed={1675}
                centeredSlides={true}
                autoplay={{
                    delay: 4300, // Sets the delay between slides
                    waitForTransition: true, // Waits until the transition is finished before autoplay
                    disableOnInteraction: false, // Keeps autoplay active after user interaction
                }}
                breakpoints={{
                    768: {
                    slidesPerView: 'auto',
                    },
                    1200: {
                    slidesPerView: 'auto',
                    },
                }}
                pagination={{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: false,
                }}
                allowTouchMove={false}
            >
                <SwiperSlide style={{padding:"5px"}}>
                    <img src="/assets/images/applystart/landing/heroCarousel01.jpg"/>
                </SwiperSlide>
                <SwiperSlide style={{padding:"5px"}}>
                <img src="/assets/images/applystart/landing/heroCarousel02.jpg"/>
                    </SwiperSlide>
                <SwiperSlide style={{padding:"5px"}}>
                    <img src="/assets/images/applystart/landing/heroCarousel03.jpg"/>
                </SwiperSlide>
                <SwiperSlide style={{padding:"5px"}}>
                    <img src="/assets/images/applystart/landing/heroCarousel04.jpg"/>
                </SwiperSlide>
            </Swiper>         */}
            </div>

        </div>
    )
}