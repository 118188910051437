import React from "react";

export const LoadingComponent = () => {
    return (
        <div id="edublink-preloader">
            <div className="loading-spinner">
                <div className="preloader-spin-1"></div>
                <div className="preloader-spin-2"></div>
            </div>
        </div>
    )
}