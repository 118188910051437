import CounterComponent from "../../components/CounterComponent";
import React from "react";

const counter_data = [
    {
        color: 'counter1',
        count: '4500',
        text: '+',
        title: 'USERS',
        // decimal: 1
    },
    {
        color: 'counter2',
        count: '800',
        text: '+',
        title: 'UNIVERSITIES',
    },
    {
        color: 'counter3',
        count: 10000,
        text: '+',
        title: 'PROGRAMS',
        border_none:'border-none'
    },
    // {
    //     color: 'extra05-color',
    //     count: 354,
    //     text: '%',
    //     title: 'Top Instructors',
    //     border_none:'border-none'
    // }
]

export const ScorecardSection = () => {
    return (
        // <div className="counterup-area-3 gap-bottom-equal">
        <div className="hero-counter-area">
            <div className="container">
                <div className="row">
                    {counter_data.map((c, i) => (
                        <div key={i} className="col-4">
                            <div className={`edu-counterup counterup-style-3 ${c.border_none ? c.border_none : '' }`} data-sal-delay="100" data-sal="slide-down" data-sal-duration="1000">
                                <h2 className={`counter-item count-number ${c.color}`}>
                                    <span className="odometer"><CounterComponent number={parseFloat(c.count)} text={c.text} decimal={c.decimal} /></span>
                                </h2>
                                <p className={`${c.color}`}>{c.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}