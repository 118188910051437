import {Link} from "react-router-dom";
import {CONTACT_DATA} from "../../constants";

const footer_contents = {
    logo: require('../../assets/brand/logo-symbol-red.svg').default,
    background: `url(${require('../../assets/svgs/footer/footerBackground.svg').default})`,
    desc: '',
    widgets: [
        {
            col: '3',
            class: 'explore-widget',
            widget_title: 'Get Started',
            footer_links: [
                {link: 'https://app.applystart.com', title: 'Apply Now', external: true},
                {link: 'https://app.applystart.com', title: 'Institutions', external: true},
                {link: 'https://app.applystart.com/programs', title: 'Programs', external: true},
                {link: '/faq', title: "FAQ's"},
                {link: '/privacy-policy', title: 'Privacy Policy'},
                { link: '/terms-conditions', title: 'Terms and Conditions' },
            ]
        },
        {
            col: '2',
            class: 'quick-link-widget',
            widget_title: 'Know Us Better',
            footer_links: [
                {link: '/team', title: 'Meet Our Team'},
                {link: '/gallery', title: 'Gallery'},
                {link: '/contact-us', title: 'Get In Touch'},
                {link: '/blogs', title: 'Blogs'},
            ]
        }
    ]

}

const {background, desc, widgets, logo} = footer_contents;

const FooterComponent = () => {
    return (
        <footer style={{background: background}}
                className={`edu-footer text-tertiary footer-dark bg-light footer-style-3`}>
            <div className={`footer-top footer-top-2`}>
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <div className="edu-footer-widget">
                                <div className="logo">
                                    <Link to={'/'}>
                                        <img className="logo-dark" src={logo} alt="Applystart Logo" width={190}/>
                                    </Link>

                                </div>

                                <p className="description ">{desc}</p>
                                <div className="widget-information">
                                    <ul className="information-list">
                                        <li><span><i className="bi bi-geo-alt-fill fs-3"/></span>{CONTACT_DATA.ADDRESS}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {widgets.map((w, i) => (
                            <div key={i} className={`col-6 col-lg-${w.col}`}>
                                <div className={`edu-footer-widget ${w.class}`}>
                                    <h4 className="widget-title">{w.widget_title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {w.footer_links.map((l, i) => <li key={i}>
                                                {
                                                    l.external ?
                                                        <a href={l.link} rel="noreferrer" target="_blank">{l.title} <img alt={"app"}
                                                            src={require("../../assets/svgs/appBadgeWhite.svg").default}
                                                            style={{width: "35px", marginLeft: "5px"}}/></a>
                                                        : <Link to={`${l.link}`}>{l.title}</Link>
                                                }
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="col-lg-4 col-md-6">
                            <div className="edu-footer-widget">
                                <h4 className="widget-title">We Are Excited to Help You</h4>
                                <div className="inner">
                                    <ul className="information-list">
                                        <li><span><i className="bi bi-envelope-at-fill fs-3"></i></span><Link
                                            to="/contact-us">{CONTACT_DATA.EMAIL}</Link></li>
                                        <li><span><i className="bi bi-telephone fs-3"></i></span><a
                                            href={`tel:${CONTACT_DATA.WHATSAPP}`}>{CONTACT_DATA.WHATSAPP}</a></li>
                                        <li><span><i className="bi bi-whatsapp fs-3"></i></span><a
                                            href={`https://wa.me/8801711566077`}>{CONTACT_DATA.WHATSAPP}</a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"bg-secondary p-1 text-center text-uppercase text-shadow-secondary"}>
                Join our community:
            </div>
            <div className="bg-tertiary box-shadow-secondary p-0">
                <div className="container fluid">
                    <div className="row">
                        <div className={"col-4 text-center border-right border-secondary m-0 p-0"}>
                            <a href={"https://www.facebook.com/Applystart.Inc"} className={"w-100 m-0"}
                               target={"_blank"} rel="noreferrer">
                                <button
                                    className={"btn w-100  text-secondary  hover-text-tertiary w-100 btn-facebook m-0 border-none"}
                                    style={{borderRadius: "0px"}}>
                                    <i className={"bi bi-facebook fs-1"}></i>
                                    <br/>
                                </button>
                            </a>
                        </div>
                        <div className={"col-4 text-center border-right border-secondary m-0 p-0"}>
                            <a href={"https://www.instagram.com/applystart/"} className={"w-100 m-0"} target={"_blank"}
                               rel="noreferrer">
                                <button
                                    className={"btn w-100  text-secondary w-100  hover-text-tertiary btn-instagram m-0 border-none"}
                                    style={{borderRadius: "0px"}}>
                                    <i className={"bi bi-instagram  fs-1"}></i>
                                    <br/>
                                </button>
                            </a>
                        </div>
                        <div className={"col-4 text-center border-secondary m-0 p-0"}>
                            <a href={"https://www.tiktok.com/@applystart.com"} className={"w-100 m-0"} target={"_blank"}
                               rel="noreferrer">
                                <button
                                    className={"btn w-100  text-secondary hover-text-tertiary w-100 btn-tiktok m-0 border-none"}
                                    style={{borderRadius: "0px"}}>
                                    <i className={"bi bi-tiktok  fs-1"}></i>
                                    <br/>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright-area bg-primary py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <p className={"footerText"}><span className="fs-3">&copy;</span> <Link
                                    className={"text-decoration-underline"} to={'/privacy-policy'}>
                                    Applystart Inc.</Link> {new Date().getFullYear()}. Provide Feedback <a
                                    href={`mailto:${CONTACT_DATA.EMAIL}`} className={"text-decoration-underline"}
                                    rel="noreferrer" target="_blank">{CONTACT_DATA.EMAIL}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterComponent;