import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../../providers/MouseMoveProvider';
import React, {useEffect, useState} from "react";
import { sendRequestToCMS } from '../../utils';
import { DATA_DEFAULTVALUES } from '../../constants';
import ApplystartButtonComponent from '../../components/ApplystartButtonComponent';
import { SmallBlogCardComponent } from '../../components/SmallBlogCardComponent';
import sal from "sal.js";



const CanadaSubSection = () => {

    const [blogs,setBlogs] = useState(DATA_DEFAULTVALUES.NULL);

    useEffect(() => {
        sendRequestToCMS(`/blogs`, setBlogs);
    }, []);

    useEffect(() => {
        sal();
    }, [blogs]);

    const { mouseDirection, mouseReverse } = useMouseMoveUI();
    return (
        <div className="edu-faq-area faq-style-1"
        style={{backgroundImage:`url(${require("../../assets/images/backgrounds/bg2.png")})`, 
            backgroundPosition: "center", 
            backgroundSize: "100% 100%",
            padding: "70px 0px 10px"
        }}
        >
            <div className="container">
                <div className="row g-5 row--45">
                    <div className="col-lg-4" data-sal-delay="100" data-sal="slide-down" data-sal-duration="800">
                        <div className="edu-faq-gallery">
                            <div className="row g-5"> 
                                <video className="canada_tutorialVideo" controls> 
                                    <source src={require("../../assets/images/views/canadaView/about_applystart.mp4")} type="video/mp4"/>
                                </video>
                            </div>

                            <ul className="shape-group">
                                <motion.li className="shape-1 scene shape-light" data-sal-delay="500" data-sal="fade" data-sal-duration="200"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseReverse(30).x,
                                        y: mouseReverse(30).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-003.png")} alt="Shape Images" />
                                </motion.li>
                                <motion.li className="shape-1 scene shape-dark" data-sal-delay="500" data-sal="fade" data-sal-duration="200"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseReverse(30).x,
                                        y: mouseReverse(30).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/dark-shape-02.png")} alt="Shape Images" />
                                </motion.li>
                                <motion.li className="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseDirection(30).x,
                                        y: mouseDirection(30).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-048b.png")} alt="Shape Images" />
                                </motion.li>
                                <motion.li className="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseDirection(30).x,
                                        y: mouseDirection(30).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-085.png")} alt="Shape Images" />
                                </motion.li>
                                <motion.li className="shape-4 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseReverse(30).x,
                                        y: mouseReverse(30).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-022d.png")} alt="Shape Images" />
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-8" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-faq-content">
                            <div className="section-title section-left mb-0">
                                <h2>How to Apply to Study in Canada?</h2>
                                <span className="shape-line">
                                    <i className="icon-19"></i>
                                </span>
                                <p>
                                    Canadian Student VISA Application is a relatively simpler procedure. You need to Create A GCKey Account on the IRCC website and submit all accurate documents on the portal to submit your VISA application successfully.
                                </p>
                                <p>
                                Check out our blogs for more useful info:
                                </p>
                            </div>
                            <div className="faq-accordion" id="faq-accordion" style={{minHeight:"500px"}}>
                                <div className="accordion"> 
                                    {(blogs !== DATA_DEFAULTVALUES.NULL && blogs.data.length > 0)?
                                            blogs.data.slice(0, 3).map(blog =>
                                                <SmallBlogCardComponent blog={blog}/>
                                        ) : null}
                                    <br/>
                                    <ApplystartButtonComponent text="Read more" link="/blogs" theme="primary hollow" iconLeft="bi bi-book-half"/>
                                </div>
                            </div>
                            <ul className="shape-group">
                                <motion.li className="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200"
                                    transition={{duration: 0}} style={{opacity:"0.5"}} animate={ {
                                        x: mouseReverse(30).x,
                                        y: mouseReverse(30).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-052.png")} alt="Shape Images" />
                                </motion.li>
                                <motion.li className="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseDirection(30).x,
                                        y: mouseDirection(30).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-099.png")} alt="Shape Images" />
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CanadaSubSection;