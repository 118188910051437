import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ApplystartButtonComponent from './ApplystartButtonComponent';
const TableView = ({ isHeader, children }) => {
    return (
        <div className={"table-responsive"}>
            <table striped>
                {children}
            </table>
        </div>
    );
};

const BlogCTABanner = (props) =>{

    return (
        <div className='ctaBanner'>
            <h1 className="ctaBanner-h1">{props.heading}</h1>
            <p className="ctaBanner-p">{props.caption}</p>
            <div className='ctaBannerContainer'>
                <ApplystartButtonComponent text={props.button} link={props.link} external={true} theme="primary"/>
                <img className="ctaBanner-img" src={require("../assets/brand/logo-symbol-red.svg").default} alt="Applystart Logo"/>
            </div>
        </div>
    )
}
const renderers = {
    table: TableView,
    image: () => <div>image</div>,
    code: ({ node, ...props }) => {
        if (props.children.includes('CTA')) {
            const heading=props.children.match(/heading="([^"]*)"/)
            const caption=props.children.match(/caption="([^"]*)"/)
            const button=props.children.match(/button="([^"]*)"/)
            const link=props.children.match(/link="([^"]*)"/)

            return <BlogCTABanner 
                heading={(heading==null || heading[1]=="") ? "Your Dream University Awaits - Start Your Application Now!" : heading[1]} 
                caption={(caption==null || caption[1]=="") ? "Begin your application now and unlock endless possibilities with Applystart." : caption[1]} 
                button={(button==null || button[1]=="") ? "Apply Now" : button[1]} 
                link={(link==null || link[1]=="") ? "https://app.applystart.com/" : link[1]} 
                />;
            }
        else return props.children
    },
    a: ({ href, children }) => {
        return (
            <a href={href} target="_blank" rel="noreferrer">
                {children}
            </a>
        );
    },
};

const MarkdownRendererComponent = ({ markdown }) => {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={renderers}
            children={markdown}
            skipHtml={false}
        />
    );
};

export default MarkdownRendererComponent;