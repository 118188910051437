import React, {useEffect, useState} from "react";
import {sendRequestToCMS} from "../utils";
import MarkdownRendererComponent from "../components/MarkdownRendererComponent";
import {DATA_DEFAULTVALUES} from "../constants";
import {LoadingComponent} from "../components/layout/LoadingComponent";

export const TermsConditionView = ()=>{
    const [content, setContent] = useState(DATA_DEFAULTVALUES.NULL);

    useEffect(() => {
        sendRequestToCMS("/terms-and-condition", setContent)
    }, []);

    if (content === DATA_DEFAULTVALUES.NULL)
        return <div className="min-vh-70"/>
        
    return (
        <section className="privacy-policy-area pt-5">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-8">
                        {!content && <LoadingComponent/>}
                        {content?.data && <MarkdownRendererComponent markdown={content.data.attributes.content}/>}
                    </div>

                   {/* <div className="col-lg-4">
                        <ul className="shape-group">
                            <motion.li className="shape-1 scene"
                                       transition={{duration:0}}
                                       animate={ {
                                           x: mouseReverse(30).x,
                                           y: mouseReverse(30).y
                                       } }
                            >
                                <img src="/assets/images/floaties/floatie-015.png" alt="Shape" />
                            </motion.li>
                            <motion.li className="shape-2 scene"
                                       transition={{duration:0}}

                            >
                                <img src="/assets/images/floaties/floatie-108.png" alt="Shape" />
                            </motion.li>
                            <li className="shape-3">
                                <img src="/assets/images/floaties/floatie-002.png" alt="Shape" />
                            </li>
                        </ul>
                    </div>*/}
                </div>
            </div>
        </section>
    )
}