import React, { useState } from 'react';
import { sendRequestToCMS } from "../../utils";

export const DocumentChecklistSection = (props) => {
  const [email, setEmail] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const HandleDownloadButton = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const userInput = {
        data: {
          email: email,
          source: props.source,
        },
      };

      try {
        await sendRequestToCMS('/document-leads', null, userInput, 'post');
        setButtonDisabled(true);

        const fileUrl = props.file;
        const fileName = props.name;
        const link = document.createElement('a');

        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        alert('Thank you! Your document checklist has started downloading.');
      } catch (err) {
        alert('Please enter a valid email address.');
      }
    }
  };

  return (
    <>
      <div className="container fluid" id={"documentList"}>
        <div className="documentChecklistContainer">
          <img
            className="documentChecklistImg"
            src={require("../../assets/svgs/iconDocuments.svg").default}
            alt="Document Icon"
          />
          <form className="documentChecklistForm" onSubmit={HandleDownloadButton}>
            <h2>Document Checklist</h2>
            <p>
              Enter your email address and get access to a handy checklist that
              will help you keep track of the necessary documents:
            </p>

            <div className="mb-3 documentChecklistFormEmail">
              <label className="form-label">
                <p>Email ID:</p>
              </label>
              <input
                type="email" // Use email type for better validation
                placeholder="Enter email"
                className="form-control documentChecklistFormEmail inputField"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Correct React syntax
              />
              <button
                type="submit"
                className={`button-custom ${buttonDisabled ? 'disabled' : 'primary'}`}
                style={{ borderWidth: '0px' }}
                disabled={buttonDisabled}
              >
                Download
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
