import AdBannerComponent from "../../components/AdBannerComponent";
import React from "react";
import BlogSidebarSection from "./BlogSidebarSection";
import { BlogListSection } from "./BlogListSection";
import {useParams} from "react-router-dom";
import { FaqSection } from "../FaqSection";
import {BlogBodySection} from "./BlogBodySection";

export const BlogView = (props) => {
    const parts = String(window.location.href).split('/');
    const faqTopic = parts[parts.length - 1];

    const blogId = useParams()?.blogId;

    return (

        <>
            <section className="section-gap-equal min-vh-100 pt-5 pb-5">
                <div className="container">
                    <div className="row row--30">
                        <div className="col-lg-8 order-lg-1">
                            {blogId ? <BlogBodySection/> : <BlogListSection/> }
                        </div>

                        <div className="col-lg-12 order-lg-3 m-0 p-0">
                            {faqTopic && faqTopic!=='blogs'? 
                            <>
                                <hr style={{borderTop: "1px dashed red"}}/><br/>
                                <FaqSection faqTopic={faqTopic}/>
                            </>
                            :null}      
                        </div>
                        
                        <div className="col-lg-4 order-lg-2">
                            <BlogSidebarSection/>
                        </div>
                    </div>
                </div>
            </section>


            <AdBannerComponent
            callText={
                <>
                <h2>Move forward with confidence</h2>
                <p>Applystart is the fast-track to your dream destination for studying abroad. It's your move — make it count!</p>
                </>
            } 
            buttonText={"Find Your Favorite Program"}
            link={"https://app.applystart.com/programs"} 
            imgRight={require("../../assets/svgs/appBadgeWhiteRed.svg").default}
            />
        </>
    )
}