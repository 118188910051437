import { prepareImageUrl } from '../utils';
import {Link} from 'react-router-dom';

export const SmallBlogCardComponent = ({blog}) =>{

    return (
        <div className='blogCardSmallContainer'>
            <Link to={`/blogs/${blog.attributes.slug}`} className='blogCardSmall'>
                <img src={prepareImageUrl(blog.attributes.image?.data?.attributes?.url)} alt={blog.attributes.title}/>
                <div className='blogCardSmallText'>
                    <h2 className=' truncate-two-lines'>{blog.attributes.title}</h2>
                    <p>{blog.attributes.description}</p>
                </div>
            </Link>
        </div>
    )
}