import axios from 'axios';
import {CMS_URL, DATA_DEFAULTVALUES} from "./constants";
export function sendRequestToCMS ( url, stateSetter, data, method="get" )
{
    let _url =  CMS_URL + "/api"+ url;
    if(method==="get") {
        if(_url.includes("?"))
            _url += "&populate=*";
        else _url += "?populate=*"
    }

    if ( stateSetter )
        stateSetter( DATA_DEFAULTVALUES.NULL );

    return new Promise((resolve, reject) => {
    axios( {
        method: method,
        url: _url,
        timeout: 40000,
        data: data
    } )
        .then( ( res  =>
        {
            if (res.data) {
                // console.log(res.data)
                if (stateSetter) stateSetter(res.data ?? DATA_DEFAULTVALUES.FAILED);
                resolve(res.data ?? DATA_DEFAULTVALUES.FAILED);
                // sal();
            } else {
                if (stateSetter) stateSetter(DATA_DEFAULTVALUES.FAILED);
                reject(DATA_DEFAULTVALUES.FAILED);
            }
            
        } ) )
        .catch( ( err ) =>
        {
            console.log(err)
            if ( stateSetter )
                stateSetter( DATA_DEFAULTVALUES.FAILED );

            reject(DATA_DEFAULTVALUES.FAILED);
        } );
    });
}

export const createMarkup = (html) => {
    return { __html: html };
};

export const prepareImageUrl = (url)=> CMS_URL+url;