import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../providers/MouseMoveProvider';
import ApplystartButtonComponent from '../components/ApplystartButtonComponent';

const ErrorView = () => {
   const { mouseDirection, mouseReverse } = useMouseMoveUI();

    return (
        <section className="section-gap-equal error-page-area">
            <div className="container">
                <div className="edu-error">
                    <div className="thumbnail">
                        <img src={require("../assets/svgs/gallery_telescope.svg").default} width={230} alt="404 Error" />
                        <ul className="shape-group">
                            <motion.li className="shape-1 scene"
                                       animate={ {
                                           x: mouseReverse(30).x,
                                           y: mouseReverse(30).y
                                       } }
                                       transition={{ duration: 0 }}
                            >
                                <img src={require("../assets/images/shapes/floatie-008.png")} alt="Shape" />
                            </motion.li>
                            <motion.li className="shape-2 scene"
                                       animate={ {
                                           x: mouseDirection(30).x,
                                           y: mouseDirection(30).y
                                       } }
                                       transition={{ duration: 0 }}
                            >
                                <img src={require("../assets/svgs/paperplane_illustration.svg").default} width={50} alt="Shape" />
                            </motion.li>

                        </ul>
                    </div>
                    <div className="content">
                        <h2 className="title text-secondary">Oops! You did not mean
                            to <a href={"https://app.applystart.com"} className={"text-primary"}>apply</a> here!</h2>
                        <h4 className="subtitle">The page you are looking for does not exist.</h4>
                        <div className="row justify-content-center">
                            <ApplystartButtonComponent text="Back to Homepage" link="/" theme="primary" iconLeft="bi bi-house-fill mx-2"/>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1">
                    <img src={require("../assets/images/shape-dots/map-shape-2.png")} alt="Shape" />
                </li>
            </ul>
        </section>
    )
}

export default ErrorView;