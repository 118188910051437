
import React, {useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import {AppContext} from "../../providers/AppProvider";

export const BreadcrumbComponent = ({ title, path }) => {
    const {currentPageTitle} = useContext(AppContext);

    useEffect(() => {
        // Get the page title and store it in a variable
        // const title = document.title;
        // setPageTitle(title);
    }, []);

    return (
        <>
            {
                title&&
                <div style={{backgroundColor: '#fbd1d1'}} className="bannerPlaceholder"></div>
            }
            <div className="edu-breadcrumb-area p-0" >
                {
                    title&&
                    <h1 className="bannerRedWhite inner-page-title">{title}</h1>
                }
                <div className="container">
                    <div className="breadcrumb-inner">

                        <ul className="edu-breadcrumb">
                            <li className="breadcrumb-item mt-0 mb-0">
                                <Link  className={"btn-link text-decoration-none"} to="/">
                                    Home
                                </Link>
                            </li>
                            {
                                path?.map(item=>
                                    <>
                                        <li className="separator mt-0 mb-0 text-dark"><i className="bi bi-chevron-right"></i></li>
                                        {currentPageTitle && item.link?
                                            <li className="breadcrumb-item mt-0 mb-0">
                                                <Link className={"btn-link text-decoration-none"} to={item.link}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                            :
                                            <li className={"breadcrumb-item btn-link text-decoration-none mt-0 mb-0"}>
                                                {item.title}
                                            </li>
                                        }
                                    </>)
                            }
                            {
                                currentPageTitle &&
                                <>
                                    <li className="separator mt-0 mb-0 text-dark"><i className="bi bi-chevron-right"></i></li>
                                    <li className={"breadcrumb-item btn-link text-decoration-none mt-0 mb-0"}>{currentPageTitle}</li>
                                </>
                            }
                        </ul>
                        <hr style={{borderTop: "1px solid", transform:"translate(0,-15px)", marginTop: "25px"}}/>
                    </div>
                </div>
            </div>
        </>
    )
}
