import React, { useRef, useState} from 'react';
import {BREVO_APIKEY, CONTACT_DATA} from "../../constants";
import axios from "axios";

const ContactUsFormSection = () => {
    const [result, setResult] = useState( <></> );
    const [isDisabled, setIsDisabled] = useState(true);
    const formRef = useRef();

    const sendEmail = ( e ) => {
        e.preventDefault();

        const emailData = {
            to: [{ email: CONTACT_DATA.LEADRECEIVINGEMAIL, name: "Applystart Team" }],
            params: {
                name: e.target.fullname.value,
                email: e.target.email.value,
                phone: e.target.phone.value,
                message: e.target.message.value
            },
            templateId:1
        };


        axios.post('https://api.brevo.com/v3/smtp/email', emailData, {
            headers: {
                'api-key': BREVO_APIKEY,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setResult( <div className={"text-success"}>We received your response! Talk to you soon!</div> );
                e.target.reset();
                setIsDisabled(true);
            })
            .catch(error => {
                setResult( <div className={"text-danger"}>Oops our apologies! An error occurred! Please email team@applystart.com. We still want to help you!</div> );
                setTimeout(() => {
                    setResult( <></> );
                }, 6000);
            });

    };



    const checkForm = () => {
        setIsDisabled(!(formRef.current.email.value && formRef.current.email.value && formRef.current.phone.value))
    }


    return (
        <form ref={formRef} className="rnt-contact-form rwt-dynamic-form" action="" onSubmit={ sendEmail }>
            <div className="row row--10">
                <div className="form-group col-12">
                    <input type="text" name="fullname" placeholder="Your name*" required onChange={checkForm}/>
                </div>
                <div className="form-group col-12">
                    <input type="email" name="email" placeholder="Enter your email*" required onChange={checkForm}/>
                </div>
                <div className="form-group col-12">
                    <input type="tel" name="phone" placeholder="Phone number*" required onChange={checkForm}/>
                </div>
                <div className="form-group col-12">
                    <textarea name="message" cols="30" rows="4" placeholder="Your message"></textarea>
                </div>
                <div>{result}</div>
                <div className="form-group col-12">
                    <button disabled={isDisabled} className={"button-custom mt-4 primary"+(isDisabled?"disabled":"")} name="submit" type="submit">Submit Message <i className="bi bi-envelope-arrow-up-fill" style={{marginLeft: "8px"}}/></button>
                </div>
            </div>

        </form>
  )
}

export default ContactUsFormSection;