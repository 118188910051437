export const CMS_URL = "https://cms.applystart.com";
export const DATA_DEFAULTVALUES = {
    NULL: null,
    EMPTY_ARRAY: [],
    FAILED: "no_data",
    LOADING: "loading_data"
}

export const BREVO_APIKEY = "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";

export const CONTACT_DATA = {
    ADDRESS: "110 Love Road, Tejgaon, Dhaka-1208, Bangladesh",
    WHATSAPP: "+880 1862-361386",
    EMAIL: "team@applystart.com",
    LEADRECEIVINGEMAIL: "team@applystart.com"
}

export const SOCIAL_LINKS = [
    { link: 'https://www.facebook.com/Applystart.Inc', target: '_blank', icon: 'bi bi-facebook', name:"facebook"},
    { link: 'https://www.instagram.com/applystart/', target: '_blank', icon: 'bi bi-instagram', name:"instagram" },
    { link: 'https://www.tiktok.com/@applystart.com', target: '_blank', icon: 'bi bi-tiktok', name:"tiktok"},
];