import {Link} from 'react-router-dom';
import React, { useState } from 'react';
import {MenuItemsSegment} from "./MenuItemsSegment";

export const NavbarComponent = ({ menu_data }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button onClick={() => setIsOpen(!isOpen)}
                    className={`mobile-nav-button ${isOpen ? "" : "hidden"}`}>
                        <img src={require(`../../../assets/svgs/iconMobileNav02.svg`).default} style={{minWidth: "40px"}}/>
            </button>
            <header className={`edu-header z-9 header-style-2`}>
                <div className={`header-mainmenu edu-sticky`}>
                    <div className="container">
                        <div className="header-navbar">
                            <div className="header-brand">
                                <Link to="/" className="logo-light" >
                                    <img src={require("../../../assets/brand/logo-full.svg").default} alt={"Applystart Logo"}/>
                                </Link>
                            </div>

                            <div className="header-mainnav">
                                <nav className="mainmenu-nav">
                                    <MenuItemsSegment menu_data={menu_data}/>
                                </nav>
                            </div>

                            <div className="header-right">
                                <ul className="header-action">

                                    <li className="header-btn-custom">
                                        <a href="https://app.applystart.com" target='_blank'
                                           className="btn-circle mb-2 " rel="noreferrer">
                                            <img src={require("../../../assets/svgs/iconPaperPlane.svg").default} alt={"floating graphic"}/>
                                        </a>
                                        <div className={"w-100 text-center navIconText"}>APPLY</div>
                                    </li>

                                   <li className="header-btn-custom">
                                        <h1 className={`btn-hamburger ${isOpen ? "hidden" : ""}`} onClick={()=> setIsOpen(true)}>
                                            <img src={require("../../../assets/svgs/iconMobileNav01.svg").default} alt={"floating graphic"}
                                                 style={{minWidth:"40px"}}/>
                                        </h1>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

            </header>

            {/* sidebar start */}
            <SideNav menu_data={menu_data} isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* sidebar end */}
        </>
    )
}

const SideNav = ({ isOpen, setIsOpen, menu_data }) => {
    const [navTitle, setNavTitle] = useState('')

    const openMobileMenu = (menu) => {
        if(navTitle === menu){
            setNavTitle('')
        }
        else {
            setNavTitle(menu)
        }
    }


    const [fadeKey, setFadeKey] = useState(0);

    const resetAnimation = () => {
        setFadeKey(prevKey => prevKey + 1);
    };

    return (
        <>
            <div className={`popup-mobile-menu ${isOpen?'active':''}`} key={fadeKey}>
                <div className="inner">
                    <div className="header-top">
                        <Link to="/" className="logo-light mobile" ></Link>
                    </div>

                    <div className="mm-menu">
                        <ul>
                            {menu_data.map((menu, i) => (
                                <li key={i} className={!menu.submenus ? '' : navTitle === menu?.title ?
                                    "has-droupdown active" : "has-droupdown"}>
                                    {menu.submenus && <button  onClick={() => openMobileMenu(menu.title)}>{menu.title} </button>}

                                    {!menu.mobile_pages_menu &&
                                        <ul className={navTitle === menu?.title ? "sub-menu active" : "sub-menu"}>
                                            {menu?.submenus?.map((sub,i) => (
                                                <li key={i} onClick={()=> {resetAnimation(); setIsOpen(false);}}>
                                                    {
                                                        sub.external?<a>
                                                                <a href={`${sub.link}`} className={""} target={"_blank"} rel="noreferrer">
                                                                    {sub.title} {' '}
                                                                    <i className={"bi bi-box-arrow-up-right fs-3"}></i>
                                                                </a>
                                                            </a>
                                                            :
                                                            <Link to={`${sub.link}`}>{sub.title}</Link>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    }

                                    {menu.mobile_pages_menu &&
                                        <ul className={navTitle === menu?.title ? "sub-menu active" : "sub-menu"}>
                                            {menu?.mobile_pages_menu?.map((sub,i) => (
                                                <li key={i} onClick={()=> {resetAnimation(); setIsOpen(false);}}><Link to={`${sub.link}`}>{sub.title}</Link></li>
                                            ))}
                                        </ul>
                                    }

                                    {!menu.submenus && <Link onClick={()=> {resetAnimation(); setIsOpen(false);}} to={menu.link}>{menu.title}</Link>}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* overlay start */}
            <div onClick={() => {resetAnimation(); setIsOpen(false);}} className={`body-overlay ${isOpen ? 'apply' : ''}`}></div>
            {/* overlay end */}
        </>
    )
}