import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {useMouseMoveUI} from "../providers/MouseMoveProvider";
import {sendRequestToCMS} from "../utils";
import MarkdownRendererComponent from "../components/MarkdownRendererComponent";
import {DATA_DEFAULTVALUES} from "../constants";
import {LoadingComponent} from "../components/layout/LoadingComponent";

export const PrivacyPolicyView = ()=>{
    const { mouseDirection, mouseReverse } = useMouseMoveUI();
    const [content, setContent] = useState(DATA_DEFAULTVALUES.NULL);

    useEffect(() => {
        sendRequestToCMS("/privacy-policy", setContent)
    }, []);

    if (content === DATA_DEFAULTVALUES.NULL)
        return <div className="min-vh-70"/>

    return (
        <section className="privacy-policy-area pt-0">
            <div className="container min-vh-70">
                <div className="row row--30">
                    <div className="col-lg-8">
                        {!content && <LoadingComponent/>}
                        {content?.data && <MarkdownRendererComponent markdown={content.data.attributes.content}/>}
                    </div>

                    <div className="col-lg-4">
                        <ul className="shape-group">
                            <motion.li className="shape-2 scene"
                                       transition={{duration:0}}
                                       animate={ {
                                        x: mouseDirection(200).x,
                                        y: mouseDirection(200).y
                                    } }
                            >
                                <img src={require("../assets/images/shapes/floatie-067.png")} alt="Shape" />
                            </motion.li>
                            <li className="shape-3">
                                <img src={require("../assets/images/shapes/floatie-099.png")} alt="Shape" />
                            </li>
                            <motion.li className="shape-1 scene"
                                       transition={{duration:0}}
                                       animate={ {
                                           x: mouseReverse(30).x,
                                           y: mouseReverse(30).y
                                       } }
                            >
                                <img src={require("../assets/images/shapes/floatie-010.png")} alt="Shape" />
                            </motion.li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}