import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {DATA_DEFAULTVALUES} from "../constants";
import {prepareImageUrl, sendRequestToCMS} from "../utils";
import {AppContext} from "../providers/AppProvider";

export const AuthorView = ()=>{
    const [authorId] = useState(useParams().id);
    const [author, setAuthor] = useState(DATA_DEFAULTVALUES.NULL);
    const {setCurrentPageTitle} = useContext(AppContext);

    useEffect(() => {
        if (authorId)
            sendRequestToCMS(`/authors/${authorId}`, setAuthor);
    }, [authorId]);

    useEffect(()=>{
        setCurrentPageTitle(author?.data.attributes.name);
    },[author]);

    const [showBlogsCount, setShowBlogsCount] = useState(6);


    if (author === DATA_DEFAULTVALUES.NULL)
        return <div className="min-vh-90"/>

    return (
        <>
            <div className="edu-team-details-area section-gap-equal min-vh-90">
                <div className="container">
                    <div className="row row--40">
                        <div className="col-lg-4">
                            <div className="team-details-thumb">
                                <div className="thumbnail">
                                    <img className={""} style={{width: "300px", height: "300px"}}
                                         src={prepareImageUrl(author.data.attributes.image.data.attributes.url)} alt="team images" />
                                </div>

                         {/*       <ul className="social-share">
                                    <li><a href="#"><i className="icon-share-alt"></i></a></li>
                                </ul>*/}
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="team-details-content">
                                <div className="main-info">
                                    <span className="subtitle">Instructor</span>
                                    <h3 className="title">{author.data.attributes.name}</h3>
                                    <span className="designation">{author.data.attributes.title}</span>

                                </div>

                                <div className="bio-describe">
                                    <h4 className="title">About Me</h4>
                                    <p>{author.data.attributes.description}</p>
                                </div>

                                <div className="">
                                    <h4 className="title">Blogs by {author.data.attributes.name}</h4>
                                    <ul>
                                        {
                                            author.data.attributes.blogs?.data?.slice(0, showBlogsCount).map(b=>
                                                <li><Link className={"btn btn-link"} to={`/blogs/${b.attributes.slug}`}>{b.attributes.title}</Link></li>
                                            )
                                        }
                                        {
                                            author.data.attributes.blogs?.data.length > showBlogsCount &&
                                            <a className={"fs-4"} onClick={()=>setShowBlogsCount(showBlogsCount+6)}>Show More</a>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}