import React, {createContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

export const AppContext = createContext( {} );
export default function AppProvider({ children }) {
    const location = useLocation();
    const [currentPageTitle, setCurrentPageTitle] = useState();

    useEffect( () => {
        // sal();


        if (location.hash) {
            setTimeout(() => {
                const targetElement = document.querySelector(location.hash);
                if (targetElement) {
                    const position = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop;
                    window.scrollTo(0, position - 150);
                }
            }, 800); // Adjust the delay as needed
        }
        else
        {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Smooth scrolling effect
            });
        }

        setCurrentPageTitle("");
    }, [location] );


    const wrapper_objects = {currentPageTitle, setCurrentPageTitle};

    return (
        <AppContext.Provider value={wrapper_objects}>
            {children}
        </AppContext.Provider>
    )
}
