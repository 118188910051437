import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../../providers/MouseMoveProvider';
import ApplystartButtonComponent from '../../components/ApplystartButtonComponent';

const HeroSection = (props) => {
    const { mouseDirection, mouseReverse } = useMouseMoveUI();
    return (
        <>
            <div>
                <img src={props.backgroundImage}  alt="" className="bannerPlaceholder" />
            </div>
        <div className="hero-banner hero-style-1" style={{backgroundImage:`url(${props.backgroundImage})`}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <h1 className={props.headingStyle} data-sal-delay="100" data-sal="slide-up" data-sal-duration="1000">{props.heading}</h1>
                            <p className="textBox whiteRed" data-sal-delay="200" data-sal="slide-up" data-sal-duration="1000">{props.subHeading}</p>
                            <br/>
                            <b><p data-sal-delay="200" data-sal="slide-up" data-sal-duration="1000" style={{textAlign:"start"}}>{props.caption}</p></b>
                            <div className="banner-btn" data-sal-delay="400" data-sal="slide-up" data-sal-duration="1000">
                                {props.hideBtn ? null : <ApplystartButtonComponent text="APPLY NOW" link="https://app.applystart.com/" external={true} theme="primary"/>}
                            </div>
                            <ul className="shape-group">
                                <motion.li className="shape-1 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000" 
                                     style={{top:"-100px", left:"-140px",scale:"0.7"}} transition={{duration: 0}} animate={ {
                                        x: mouseReverse(25).x,
                                        y: mouseReverse(25).y
                                    } }
                                >
                                    <img src={require(`../../assets/images/shapes/floatie-${props.floaties[0]}.png`)} alt="Shape"/>
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-thumbnail">
                            <div className="thumbnail" data-sal-delay="500" data-sal="slide-left" data-sal-duration="1000">
                                <img src={props.flag} alt="Country Flag" />
                            </div>

                            <ul className="shape-group">
                                <motion.li className="shape-5 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseReverse(25).x,
                                        y: mouseReverse(25).y
                                    } }
                                >
                                    <img src={require(`../../assets/images/shapes/floatie-${props.floaties[1]}.png`)} alt="Shape" />
                                </motion.li>
                                <motion.li className="shape-6 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseDirection(20).x,
                                        y: mouseDirection(20).y
                                    } }
                                >
                                    <img src={require(`../../assets/images/shapes/floatie-${props.floaties[2]}.png`)} alt="Shape" />
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default HeroSection;