import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import PaginationComponent from "../../components/PaginationComponent";
import {prepareImageUrl, sendRequestToCMS} from "../../utils";
import {DATA_DEFAULTVALUES} from "../../constants";
import sal from "sal.js";
import { Helmet } from "react-helmet";

export const BlogListSection = () => {
    const [blogs, setBlogs] = useState(DATA_DEFAULTVALUES.NULL);
    const [pageIndex, setPageIndex] = useState(0);
    const takePerPage = 12;

    useEffect(() => {
        window.scrollTo(0, 0);
        sendRequestToCMS(`/blogs?fields[0]=title&fields[1]=description&fields[2]=publishDate&fields[3]=slug&pagination[page]=${pageIndex}&pagination[pageSize]=${takePerPage}`, setBlogs);
    }, [pageIndex]);

    useEffect(() => {
        sal();
    }, [blogs]);

    return (
        <>
            <Helmet>
                <title>{"International University Applications Made Easy | Applystart"}</title>
                <meta name={"description"} content={"Applystart offers easier, cheaper, and faster international university applications from the comfort of home. Connect to dream universities on one platform"}/>
                <meta property={"og:title"} content={"International University Applications Made Easy | Applystart"}/>
                <meta property={"og:description"} content={"Applystart offers easier, cheaper, and faster international university applications from the comfort of home. Connect to dream universities on one platform"}/>
                <link rel={"canonical"} href={"https://applystart.com/blogs"}/>
            </Helmet>
            
            <div className={"row"}>
            {
                blogs?.data?.map(blog =>
                    <div className={"col-12 col-md-6 col-xl-4 mb-5"} data-sal={"zoom-out"} data-sal-duration="700" key={blog.id}>
                        <Link to={`/blogs/${blog.attributes.slug}`}>

                            <div className="blog-card card" style={{height: "300px"}}>

                                <img style={{objectFit:"cover"}} className={"loading"}  onLoad={(e)=>e.target.classList.remove('loading')}
                                    src={prepareImageUrl(blog.attributes.image?.data?.attributes?.url)}/>
                                <div className={"card-body px-4"}>
                                    <div className={"card-title fs-4 fw-bold overflow-hidden truncate-two-lines"}>{blog.attributes.title}</div>
                                    <p className={"fs-5 text-truncate card-text"} style={{width:"210px"}}>
                                        {blog.attributes.description}
                                    </p>
                                </div>
                                <div className="card-footer text-center footer-textbox">
                                    <i className="bi bi-calendar2-event"></i>
                                    <small> {blog.attributes.publishDate}</small>
                                </div>

                            </div>


                        </Link>
                    </div>
                )}

            {
                blogs?.data &&
                <ul className="edu-pagination top-space-30 justify-content-start">
                    {/* pagination start */}
                    <PaginationComponent data={blogs} setPageIndex={setPageIndex}/>
                    {/* pagination end */}
                </ul>
            }
            </div>
        </>
    )
}