import React from "react";
import {motion} from "framer-motion";
import {useMouseMoveUI} from "../../providers/MouseMoveProvider";
import {CONTACT_DATA, SOCIAL_LINKS} from "../../constants";
import ContactUsFormSection from "./ContactUsFormSection";

export const ContactUsView = ()=>{
    const { mouseDirection, mouseReverse } = useMouseMoveUI();

    return(
        <div className='sticky-header'>
            <div id="main-wrapper" className="main-wrapper">
                <section className="contact-us-area" style={{padding: "40px 0"}}>
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="contact-us-info">
                                    <h3 className="heading-title">{"We're"} Always Eager to Hear From You!</h3>
                                    <ul className="address-list">
                                        <li>
                                            <h5 className="title">Address</h5>
                                            <p>{CONTACT_DATA.ADDRESS}</p>
                                        </li>
                                        <li>
                                            <h5 className="title">Email</h5>
                                            <p><a href={`mailto:${CONTACT_DATA.EMAIL}`}  rel="noreferrer" target="_blank">{CONTACT_DATA.EMAIL}</a></p>
                                        </li>
                                        <li>
                                            <h5 className="title">Phone / Whatsapp</h5>
                                            <p><a href={`tel:${CONTACT_DATA.WHATSAPP}`}>{CONTACT_DATA.WHATSAPP}</a></p>
                                        </li>
                                    </ul>
                                    <ul className="social-share">
                                        {SOCIAL_LINKS.map((social, i) => (
                                            <li key={i}><a href={social.link} className={`border-0 text-secondary hover-text-tertiary btn-${social.name}`} target={social.target ? social.target : ''}>
                                                <i className={social.icon}></i>
                                            </a></li>
                                        ))}
                                        {/*<SocialShare/>*/}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="contact-form form-style-2">
                                    <div className="section-title">
                                        <h4 className="title">Get In Touch</h4>
                                        <p>Fill out this form for booking a consultant advising session.</p>
                                    </div>
                                    {/* form start */}
                                    <ContactUsFormSection />
                                    {/* form end */}
                                    <ul className="shape-group">
                                        <motion.li className="shape-1 scene" style={{zIndex:1}}
                                                   transition={{duration:0}} animate={ {
                                                       x: mouseReverse(30).x - 120,
                                                       y: mouseReverse(30).y
                                                   } }
                                        >
                                            <img src={require("../../assets/svgs/paperplane_illustration.svg").default} width={100} alt="Shape" />

                                        </motion.li>
                                        <motion.li className="shape-2 scene" style={{zIndex:2}}
                                                   transition={{duration:0}} animate={ {
                                                       x: mouseDirection(30).x,
                                                       y: mouseDirection(30).y
                                                   } }
                                        >
                                            <img src={require("../../assets/images/shapes/floatie-108.png")} alt="Shape" />
                                        </motion.li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}