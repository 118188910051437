import React, {useContext, useEffect, useState} from "react";
import {prepareImageUrl, sendRequestToCMS} from "../../utils";
import {DATA_DEFAULTVALUES} from "../../constants";
import {useParams} from "react-router-dom";
import MarkdownRendererComponent from "../../components/MarkdownRendererComponent";
import {AppContext} from "../../providers/AppProvider";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Helmet } from "react-helmet";
import sal from "sal.js";

export const BlogBodySection = () => {

    const {blogId} = useParams();
    const [blog,setBlog] = useState(DATA_DEFAULTVALUES.NULL);
    const {setCurrentPageTitle} = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        sendRequestToCMS(`/blogs/${blogId}`, setBlog);
    }, [blogId]);

    useEffect(() => {
        sal();
        if(blog === DATA_DEFAULTVALUES.NULL)
            return;

        setCurrentPageTitle(blog.data.attributes.title);

        const script        = document.createElement('script');
        script.type         = 'application/ld+json';
        const articleSchema = {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": blog.data.attributes.title,
            "image": [
                prepareImageUrl(blog.data.attributes.image?.data.attributes.url)
            ],
            "datePublished": blog.data.attributes.publishDate,
            "dateModified": new Date(blog.data.attributes.updatedAt)?.toDateString(),
            "author": {
                "@type": "Person",
                "name": blog.data.attributes.author?.data?.attributes.name
            },
            "description": blog.data.attributes.description,
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": `https://applystart.com/blogs/${blog.data.attributes.slug}`
            },
            "keywords": blog.data.attributes.keywords
        };
        script.innerHTML = JSON.stringify(articleSchema);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [blog, setCurrentPageTitle]);
    
    if(!blog || blog === DATA_DEFAULTVALUES.NULL)
        return;

    return (
        <>
        {
            blog && blog !== DATA_DEFAULTVALUES.NULL &&
            <Helmet>
                <title>{blog.data.attributes.title}</title>
                <meta name="description" content={blog.data.attributes.description}/>
                <meta name="keywords" content={blog.data.attributes.keywords}/>
                <meta name="author" content={blog.data.attributes.author?.data.attributes.name}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={blog.data.attributes.title}/>
                <meta property="og:description" content={blog.data.attributes.description}/>
                <meta property="og:image" content={`${prepareImageUrl(blog.data.attributes.image?.data.attributes.url)}`}/>
                <meta property="og:url" content={"https://applystart.com/blogs/"+blog.data.attributes.slug}/>
                <link rel="canonical" href={"https://applystart.com/blogs/"+blog.data.attributes.slug}/>
            </Helmet>
        }
            
        {
            blog && blog !== DATA_DEFAULTVALUES.LOADING &&
            <>
                <div className="container m-0 p-0">
                    <p className="blogTitle">{blog.data.attributes.title}</p>
                    <p className="blogAuthor">
                        Published on {blog.data.attributes.publishDate}
                        {blog.data.attributes.author?.data &&
                            <>
                                {' '}by: {' '}
                                <Link to={`/authors/${blog.data.attributes.author?.data?.id}`}>
                                    <b>{blog.data.attributes.author?.data.attributes.name}</b>
                                </Link>
                            </>
                        }
                    </p>
                    <div style={{backgroundImage:`url(${prepareImageUrl(blog.data.attributes.image?.data.attributes.url)})`}} className="blogBanner"/>
                </div>
                <br/>
                <hr/>


                <div className="container m-0 p-0">
                    <div className={"blogBody"}>                        
                        <MarkdownRendererComponent markdown={blog.data.attributes.content}/>
                    </div>
                </div>

            </>
        }
        </>
    )
}