import React from "react";
import HeroSection from "./HeroSection";
import WhyChooseSection from "./WhyChooseSection";
import CostsSection from "./CostsSection";
import BenefitsSection from "./BenefitsSection";
import {DocumentChecklistSection} from "./DocumentChecklistSection";
import {FaqSection} from "../FaqSection";
import SubHeroSection from "../landingView/SubHeroSection";
import {Helmet} from "react-helmet";
import {ConsultantsSection} from "./ConsultantsSection";


export const UsaView = () => {

    // HERO BANNER
    const backgroundImage = require("../../assets/images/views/usaView/usaBannerB.jpg");
    const flag = require("../../assets/images/views/usaView/usaBannerA.png");

    const whyChooseItems = [
        {
            image: require("../../assets/images/views/usaView/cardImg01.jpg"),
            title: "Globally recognized education from <b>top ranked Universities</b>"
        },
        {
            image: require("../../assets/images/views/usaView/cardImg02.jpg"),
            title: "<b>Cutting edge</b> learning and research opportunities"
        },
        {
            image: require("../../assets/images/views/usaView/cardImg03.jpg"),
            title: '"Melting pot" of <b>diverse cultures</b>'
        },
        {
            image: require("../../assets/images/views/usaView/cardImg04.jpg"),
            title: "Promising <b>job prospects</b> after graduation"
        },
        {
            image: require("../../assets/images/views/usaView/cardImg05.jpg"),
            title: "Hefty scholarship and grant <b>opportunities</b>"
        },
    ];

    // COSTS OF STUDYING IN USA
    const costItems = [{
        title: "State College / University",
        text: `Bachelor Degree:<br/><h3>$25,000</h3>Postgraduate Degree:<br/><h3>$20,000 - $35,000</h3>`
    }, {
        title: "Private College / University",
        text: `Bachelor Degree:<br/><h3>$34,740</h3>Postgraduate Degree:<br/><h3>$40,000</h3>`
    },];


    // BENEFITS
    const benefitItems = [
        { icon:'iconCheckmark.svg',title: "Easier", subtitle: "All programs and requirements across 460+ US  institutions in one place"},
        {     icon:'iconDollar.svg',title: "Cheaper", subtitle: "Free counseling, program recommendations and application processing by experts"},
        {      icon:'iconStopwatch.svg',title: "Faster", subtitle: "Submit multiple applications to different institutions in just a few clicks"},
        {         icon:'iconTarget.svg',title: "Accurate", subtitle: "Get queries answered accurately by international students and US immigration lawyers"},
    ]

    // SCHOLARSHIP BANNER FOR USA
    const heroSubItems = () => {
        return (
            <div className="HeroSubItems1">
                <div className="textBox whiteRed p-4" data-sal-delay="150" data-sal="slide-left"
                     data-sal-duration="800">
                    <img src={require("../../assets/images/views/usaView/icon01.png")} className="usa_icon01" alt="Checklist Icon"/>
                    <span className="usa_bannerText01">Significant Financial Assistance is available to students in the USA that includes: <b>Scholarships and Grants, Institutional Aid, Private Loans.</b></span>
                </div>
                <div className="textBox whiteRed p-4" data-sal-delay="300" data-sal="slide-left"
                     data-sal-duration="800">
                    <img src={require("../../assets/images/views/usaView/icon02.png")} className="usa_icon01" alt="Checklist Icon"/>
                    <span className="usa_bannerText01">Application requirements include <b>academic requirements and language proficiency requirements.</b> These requirements vary greatly between different programs at different universities.</span>
                </div>
                <div className="textBox whiteRed p-4" data-sal-delay="450" data-sal="slide-left"
                     data-sal-duration="800">
                    <img src={require("../../assets/images/views/usaView/icon03.png")} className="usa_icon01" alt="Checklist Icon"/>
                    <span className="usa_bannerText01">Application deadlines for US Institutions can be broken into different categories: <b>Regular Decision, Early Action, Early Decision, and Rolling Admission.</b></span>
                </div>
            </div>
        )
    }


    return (

        <>
            <Helmet>
                <title>{"Study in the USA: Apply with Applystart"}</title>
                <meta name={"description"}
                      content={"Explore the benefits of studying in the USA, find study loans, scholarships, and expert consultants to guide you. Start your journey to academic excellence today."}/>
                <meta property={"og:title"} content={"Study in the USA: Apply with Applystart"}/>
                <meta property={"og:description"}
                      content={"Explore the benefits of studying in the USA, find study loans, scholarships, and expert consultants to guide you. Start your journey to academic excellence today."}/>
            </Helmet>

            <HeroSection
                backgroundImage={backgroundImage}
                flag={flag}
                heading={"Study in the USA"}
                headingStyle={"bannerWhiteRed"}
                subHeading={"Your Path to Success"}
                caption={"The United States of America has been a highly desirable study abroad destination for students for ages. It caters to their need for world-class education from highest ranked universities globally and paves the path for ambitious students to live the 'American Dream'."}
                floaties={["014", "099", "022d"]}
            />

            <WhyChooseSection
                title={"Why Choose the USA for Higher Education"}
                subtitle={"Studying in the USA has been the stepping stone for millions of international students who now call the US their home. Here are a few highlights of the USA that make it an extremely popular study abroad destination:"}
                caption={"USA is the top choice for studying abroad by more than 1,000,000 international students. This is majorly fuelled by the presence of most globally reputed universities in the US like Harvard, MIT, and Stanford University. More than half of the Top 20 universities are in the USA, making it an unmissable option for international students in their application journey."}
                items={whyChooseItems}
            />

            {/* <PopularInstitutions/> */}

            <CostsSection
                title={"Cost to Study in the USA"}
                subtitle={'Estimated Tuition Fees in USA can be grouped in the following categories:'}
                caption={"Estimated Living Cost in USA can range between $800 and $1000 per month depending on the state of residence and lifestyle of the student. Some of the types of costs you need to account for BEFORE coming to US are:"}
                items={costItems}
            />

            <div className="container fluid">
                <img src={require("../../assets/images/views/usaView/costDiagram.png")}
                     id="usa_costDiagram" alt="USA Cost Diagram"
                     style={{transform: 'translate(0, -60px)', marginBottom: '50px'}}
                />
            </div>

            <SubHeroSection
                bgImg={require("../../assets/images/views/usaView/sectionImg02.jpg")}
                img={require("../../assets/images/views/usaView/sectionImg01.png")}
                title={"Scholarships for International Students in the USA"}
                className={"bannerBlackWhite"}
                content={heroSubItems}
                floaties={["003", "099", "062"]}
            />

            <div className="container fluid" style={{paddingTop: "80px"}}>
                <h2>Exploring Study Loan Options in the USA</h2>
                <p>Student loans are available in the US for international students which are often at <b>low interest
                    rates and with fixed repayment terms,</b> with the requirement for a cosignor.</p>
                <p style={{marginBottom: "0px"}}>Applications for Student loans in the US can be submitted online, along
                    with a cosignor. The general repayment options are <b>full deferral, interest only, immediate
                        repayment.</b></p>
            </div>

            <div className="container fluid">
                <div className="usa_budgetingSection">
                    <div className="usa_budgetingContainer" data-sal-delay="150" data-sal="slide-right"
                         data-sal-duration="800">
                        <img className="usa_budgetingImg" src={require("../../assets/images/views/usaView/sectionImg03.png")}
                             alt="Budgeting Tips USA"/>

                        <div className="usa_budgetingChecklist">
                            <div className="checklist white" data-sal-delay="150" data-sal="slide-left"
                                 data-sal-duration="800">
                                <img src={require("../../assets/svgs/star1.svg").default} alt="Checklist Icon"/>
                                <p>Record and Track your <b>Income and Expenses</b></p>
                            </div>
                            <div className="checklist white" data-sal-delay="300" data-sal="slide-left"
                                 data-sal-duration="800">
                                <img src={require("../../assets/svgs/star1.svg").default} alt="Checklist Icon"/>
                                <p>Use Personal Network at university to land an <b>On-Campus Job</b></p>
                            </div>
                            <div className="checklist white" data-sal-delay="450" data-sal="slide-left"
                                 data-sal-duration="800">
                                <img src={require("../../assets/svgs/star3.svg").default} alt="Checklist Icon"/>
                                <p>Rent or Buy Your Textbooks Second-Hand and sell your unused books to <b>declutter
                                    your dorm</b> and <b>release some cash</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConsultantsSection/>

            <BenefitsSection
                title={"At Applystart, we make US applications —"}
                items={benefitItems}
            />

            <DocumentChecklistSection
                source={"usaDocumentChecklist"}
                file={require("../../assets/pdfs/documentChecklist-usa.pdf")}
                name={"Document Checklist for USA"}
            />

            <hr style={{borderTop: "1px dashed red"}}/>
            <br/>

            <FaqSection faqTopic={"usa"}/>
        </>
    )
}