import React from 'react';

const CostsSection = (props) => {
    const createMarkup = (html) => {
        return { __html: html };
      };
    return (
        <div className="container fluid">
            <div className="section">
                <h2 className="title red" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">{props.title}</h2>
                <p dangerouslySetInnerHTML={createMarkup(props.subtitle)}></p>
                <div className="titleParagraphCardRow" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <div className="col-xl-4 col-md-12 usa_iconMoneyContainer">
                        <img src={require('../../assets/svgs/iconMoney.svg').default} className="usa_iconMoney" alt="Money Icon"/>
                    </div>
                    <div className="titleParagraphCardContainer">
                        {
                            props.items.map(item=>
                                <div className="titleParagraphCard">
                                    <h1>{item.title}</h1>
                                    <p dangerouslySetInnerHTML={createMarkup(item.text)}></p>
                                </div>
                            )
                        }
                    </div>
                </div><br/>
                <p dangerouslySetInnerHTML={createMarkup(props.caption)}></p>
            </div>
        </div>
    )
}
export default CostsSection;