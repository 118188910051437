import React, {useEffect, useState} from "react";
import { sendRequestToCMS } from "../../utils";
import { DATA_DEFAULTVALUES } from "../../constants";
import sal from "sal.js";

const TestimonialMini = (props) => {
    if(props.position == 'right') {
        return (
            <div className="row" data-sal-delay="150" data-sal="slide-left" data-sal-duration="800">
                <div className="col-lg-4"></div>
                <div className="col-lg-8">
                    <div className="testimonialContainer Right">
                        <h1 style={{textAlign: "end"}}>{props.name}</h1>
                        {props.designation?<h2 style={{textAlign: "end"}}>{props.designation}</h2>:""}
                        <p>{props.comment}</p>
                    </div>
                </div>
            </div>
        )
    }
    else return (
        <div className="row" data-sal-delay="150" data-sal="slide-right" data-sal-duration="800">
            <div className="col-lg-8">
                <div className="testimonialContainer Left">
                    <h1 style={{textAlign: "start"}}>{props.name}</h1>
                    {props.designation?<h2 style={{textAlign: "start"}}>{props.designation}</h2>:""}
                    <p>{props.comment}</p>
                </div>
            </div>
            <div className="col-lg-4"></div>
        </div>
    )
}

export const TestimonialsSection = (props) => {

    const [testimonials,setTestimonials] = useState(DATA_DEFAULTVALUES.NULL);

    useEffect(() => {
        sendRequestToCMS(`/testimonial-minis?filters[topic][$eq]=${props.testimonialTopic}`, setTestimonials);
    }, []);

    useEffect(() => {
        sal();
    }, [testimonials]);

    

    return (
        <div className="container fluid">
            <h2>Testimonials</h2>
            <div className="testimonialsHeader">
                <img src={require("../../assets/svgs/iconSupport.svg").default} alt="Confetti Icon"/>
                <p style={{color:"#fff"}}><b>Over 3,000 students</b> have faith in our mission to make university applications easier.</p>
            </div>

            <div className="testimonialsList">
                <div className="row testimonialApostrophe">
                    <img src={require("../../assets/svgs/apostropheOpen.svg").default} alt="Apostrophe Icon"/>
                </div>

                {(testimonials !== DATA_DEFAULTVALUES.NULL && testimonials.data.length > 0)?
                    testimonials.data.map((testimonial, index) => 
                            <TestimonialMini 
                            key={index} 
                            name={testimonial.attributes.name} 
                            designation={testimonial.attributes.designation} 
                            comment={testimonial.attributes.comment}
                            position={index%2==0? "right" : "left"}
                            /> 
                    ) : null
                }

                <div className="row testimonialApostrophe Close">
                    <img src={require("../../assets/svgs/apostropheClose.svg").default} alt="Apostrophe Icon"/>
                </div>
            </div>
        </div>
    )
}

export default TestimonialsSection;