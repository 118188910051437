import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

const HorizontalCarouselComponent = (props) => {
  const [loop, setLoop] = useState(false);

  useEffect(() => setLoop(true), [])
    return (
        <>
            <Swiper
                className="course-activation"
                slidesPerView={1}
                modules={[Autoplay, Pagination]}
                spaceBetween={0}
                loop={loop}
                grabCursor={true}
                speed={1000}
                autoplay={{
                    delay: 3000
                }}
                breakpoints={{
                    768: {
                        slidesPerView: 2
                    },
                    1200: {
                        slidesPerView: 3
                    }
                }}
                pagination={{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                }}
            >
                {props.items.data.map((item) => {
                    return (
                        <SwiperSlide>
                            <props.component item={item}/> 
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <div className="swiper-pagination"></div>
        </>

    )
}

export default HorizontalCarouselComponent;