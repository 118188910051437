import React from 'react';
import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../../providers/MouseMoveProvider';

function FeatureBox({ img, text, sal }) {
    const createMarkup = (html) => {
        return { __html: html };
      };

    return (
        <div className="col-md-4 col-sm-6" data-sal-delay="150" data-sal={sal} data-sal-duration="800">
            <div className={`why-choose-box-3 features-box color-secondary-style`}>
                <div className="thumbnail">
                    <img src={img} alt="why choose image" />
                </div>
                <div className="content">
                    <p dangerouslySetInnerHTML={createMarkup(text)}></p>
                </div>
            </div>
        </div>
    )
}

const WhyChooseSection = (props) => {
    const { mouseDirection, mouseReverse } = useMouseMoveUI();

    return (
        <section className="why-choose-area-4 edu-section-gap" 
        style={{backgroundImage: `url(${require("../../assets/images/backgrounds/bg1.jpg")})`, backgroundPosition: "center", backgroundSize: "100% 100%"}}>
            <div className="container edublink-animated-shape">
                <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <h2 className="title">{props.title}</h2>
                    <img src={require(`../../assets/svgs/underline-primary.svg`).default} style={{height: "15px"}}/>
                </div>
                <p>{props.subtitle}</p>
                <div className="row g-5 d-flex align-items-center justify-content-center">
                    {props.items.map((item,index) =>
                        <FeatureBox 
                        key={index} 
                        img={item.image}
                        text={item.title}
                        sal={index%2==0? "slide-right" : "slide-left"}/>
                    )}
                </div>
                <br/><br/>
                <p>{props.caption}</p>
                <ul className="shape-group">
                    <li className="shape-1" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                        <img className="rotateit" src={require("../../assets/images/shapes/floatie-017.png")} alt="shape" />
                    </li>
                    <motion.li className="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200"
                        transition={{duration: 0}} animate={ {
                            x: mouseReverse(40).x,
                            y: mouseReverse(40).y
                        } }
                    >
                        <span></span>
                    </motion.li>
                    {/* <li className="shape-3 circle scene sal-animate" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                        <span className="d-block"></span>
                    </li> */}
                    <motion.li className="shape-3 circle scene sal-animate" data-sal-delay="500" data-sal="fade" data-sal-duration="200"
                        transition={{duration: 0}} animate={ {
                            x: mouseDirection(40).x,
                            y: mouseDirection(40).y
                        } }
                    >
                        <span className="d-block"></span>
                    </motion.li>
                    
                </ul>
            </div>
        </section>
    )
}

export default WhyChooseSection;