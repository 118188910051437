import React from 'react';
import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../../providers/MouseMoveProvider';
import { Link } from 'react-router-dom/cjs/react-router-dom';


const categories = [
    {
        title:'Programs',
        text:'That suit you',
        link: 'https://app.applystart.com/programs',
        external: true
    },
    {
        title:'Advisors ',
        text:'Who are always there',
        link: '/canada/#consultantsList',
    },
    {
        title:'Support',
        text:'That never charges you',
        link: 'https://calendly.com/applystart-advisingteam/new-students-advising-session-with-applystart',
        external: true
    }
]

const FlyingBannerSection = () => {
    const { mouseDirection } = useMouseMoveUI();

    return (
        <div className="landing-clouds-main">
            <div className="features-area-3">
                <div className="container edublink-animated-shape">
                    <h2>All-In-One-Platform Created By Students, For Students</h2>
                    <div className="plane-img" alt="Shape" />
                    <div className="features-grid-wrap landing-clouds-features" data-sal-delay="150" data-sal="slide-right" data-sal-duration="800">
                        {categories.map((c,i) => (
                            c.external ?
                            <a key={i} 
                                href={c.link} rel="noreferrer" target="_blank" 
                                className={`features-box features-style-3 edublink-svg-animate align-items-center landing-clouds-features-link`}
                            >
                                <div className={`feature-icon icon${i}`}></div>
                                <div className="content">
                                    <h3 className={`title h3${i}`}>{c.title}</h3>
                                    <p className={`p${i}`}>{c.text}</p>
                                </div>
                            </a>
                            :
                            <Link to={c.link} className={`features-box features-style-3 edublink-svg-animate align-items-center landing-clouds-features-link`}>
                                <div className={`feature-icon icon${i}`}></div>
                                <div className="content">
                                    <h3 className={`title h3${i}`}>{c.title}</h3>
                                    <p className={`p${i}`}>{c.text}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <ul className="shape-group">
                        <motion.li className="shape-1 scene plane-floatie"
                            style={{top:"70px",left:"0px", zIndex:"-10"}} transition={{duration: 0}} animate={ {
                                x: mouseDirection(30).x,
                                y: mouseDirection(30).y
                            } }
                        >
                            <img src={require("../../assets/images/views/landingView/plane.png")} className="plane-floatie-animation" alt="Shape" />
                        </motion.li>
                    </ul>
                </div>
                <div className="wave-container landing-clouds">
                    <div className="waveR wave1R"></div>
                    <div className="waveR wave2R"></div>
                    <div className="waveR wave3R"></div>
                </div>
            </div>
        </div>
    )
}

export default FlyingBannerSection;