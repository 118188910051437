import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectCoverflow } from "swiper";

const country_contents = {
    pre_title: 'Destinations',
    title: 'The world at your fingertips',
    text: "From the hustling New York City to the serene Niagara Falls, Applystart has your educational journey mapped out. No matter where your dreams take you, we've got all destinations covered. Check out the destinations available here!",
    country_data: [
        {
            flag: require('../../assets/images/views/landingView/countryCardFlag01.jpg'),
            name: 'CANADA',
            thumbnail: require('../../assets/images/views/landingView/countryCardThumbnail01.jpg'),
            bp1: 'Globally Recognized Degrees',
            bp2: 'Work Opportunities During Study',
            bp3: 'Friendly Immigration Policies'
        },
        {
            flag: require('../../assets/images/views/landingView/countryCardFlag02.jpg'),
            name: 'UNITED STATES OF AMERICA',
            thumbnail: require('../../assets/images/views/landingView/countryCardThumbnail02.jpg'),
            bp1: 'Diverse Portfolio Of Universities',
            bp2: 'Ample Career Opportunities',
            bp3: 'Research-Intense Education'
        },
        {
            flag: require('../../assets/images/views/landingView/countryCardFlag03.jpg'),
            name: 'UNITED KINGDOM',
            thumbnail: require('../../assets/images/views/landingView/countryCardThumbnail03.jpg'),
            bp1: 'Oldest & Prestigious Universities',
            bp2: 'Affordable Programs',
            bp3: 'Shorter Program Length'
        },
    ]
}

const { pre_title, country_data, text, title } = country_contents;

const CountryCardSection = () => {
    const [loop, setLoop] = useState(false);
    useEffect(() => setLoop(true), [])
    return (
        <div className="lcc-container">
            <div className="container ">
                <div className="row g-lg-5">
                    <div className="col-lg-7 mt-5 pt-5">
                        <Swiper
                            loop={loop}
                            speed={500}
                            slidesPerView={1}
                            centeredSlides={true}
                            effect="coverflow"
                            grabCursor={true}
                            modules={[Autoplay, EffectCoverflow, Pagination]}
                            className="swiper-testimonial-slider-wrapper swiper testimonial-coverflow"
                            autoplay={{
                                delay: 1500
                            }}
                            breakpoints={{
                                575: {
                                    slidesPerView: 2
                                }
                            }}
                            coverflowEffect={{
                                rotate: 0,
                                slideShadows: false,
                                depth: 180,
                                stretch: 80
                            }}
                            pagination={{
                                el: '.swiper-pagination',
                                type: 'bullets',
                                clickable: true
                            }}
                        >
                            {country_data.map((item, i) => (
                                <SwiperSlide key={i}>
                                <div className="landing-country-card">
                                    <div className="lcc-header">
                                        <img src={item.flag} alt={item.name + "flag"}/>
                                        <h3>{item.name}</h3>
                                    </div>
                                    <img src={item.thumbnail} className="lcc-thumbnail"/>
                                    <div className="lcc-bullet-point">
                                        <img src={require("../../assets/svgs/iconCheckmarkCircledRed.svg").default}/>
                                        <p>{item.bp1}</p>
                                    </div>
                                    <div className="lcc-bullet-point">
                                        <img src={require("../../assets/svgs/iconCheckmarkCircledRed.svg").default}/>
                                        <p>{item.bp2}</p>
                                    </div>
                                    <div className="lcc-bullet-point">
                                        <img src={require("../../assets/svgs/iconCheckmarkCircledRed.svg").default}/>
                                        <p>{item.bp3}</p>
                                    </div>
                                </div>
                                </SwiperSlide>
                            ))}
                            <br/><br/>
                            <div className="swiper-pagination"></div>
                        </Swiper>
                    </div>
                    <div className="col-lg-5 mt-5 pt-5">
                        <div className="testimonial-heading-area">
                            <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <span className="pre-title">{pre_title}</span>
                                <h2 className="title">{title}</h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                                <p>{text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CountryCardSection;