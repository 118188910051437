import React from 'react';
import ApplystartButtonComponent from '../../components/ApplystartButtonComponent';

const career_contents = {
    career_data: [
        {
            title: 'Accountant',
            icon: require('../../assets/images/views/landingView/careerIcon01.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Biomedical Engineer',
            icon: require('../../assets/images/views/landingView/careerIcon02.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Scientist',
            icon: require('../../assets/images/views/landingView/careerIcon03.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Fashion Designer',
            icon: require('../../assets/images/views/landingView/careerIcon04.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Data Analyst',
            icon: require('../../assets/images/views/landingView/careerIcon05.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Architect',
            icon: require('../../assets/images/views/landingView/careerIcon06.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Musician',
            icon: require('../../assets/images/views/landingView/careerIcon07.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Artist',
            icon: require('../../assets/images/views/landingView/careerIcon08.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Environmental Engineer',
            icon: require('../../assets/images/views/landingView/careerIcon09.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Software Engineer',
            icon: require('../../assets/images/views/landingView/careerIcon10.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Digital Marketer',
            icon: require('../../assets/images/views/landingView/careerIcon11.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Lawyer',
            icon: require('../../assets/images/views/landingView/careerIcon12.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Sales Specialist',
            icon: require('../../assets/images/views/landingView/careerIcon13.png'),
            link: 'https://app.applystart.com/programs',
        },
        {
            title: 'Web Designer',
            icon: require('../../assets/images/views/landingView/careerIcon14.png'),
            link: 'https://app.applystart.com/programs',
        },
    ]
}

const { career_data } = career_contents;

const CareerSection = () => {
    return (
        <>
            <style>

            </style>

        <div className="landing-career-options-container">
            <div className="container fluid">
                <h2 className="whiteBlack"> Choose Your Story, Build Your Future, With Applystart</h2>
                <div className="lco-tiles-container">
                    {career_data.map((item, i) => (
                        <div className="lco-tile" href={item.link}  rel="noreferrer" target="_blank" data-sal-delay={i*50} data-sal="slide-up" data-sal-duration="800">
                            <p>{item.title}</p>
                            <img src={item.icon}/>
                            {/* <h4>EXPLORE PROGRAMS <i class="bi bi-arrow-up-right-square-fill" style={{marginLeft: '5px'}}></i></h4> */}
                        </div>
                    ))}
                </div>
                <div className='lco-bottom'>
                    <p>At Applystart, you can craft your story in <b>10,000+ more possibilities</b>.</p>
                    <ApplystartButtonComponent text="View all programs" link="https://app.applystart.com/programs" external={true} theme="tertiary hollow" imgRight={require("../../assets/svgs/appBadgeWhiteRed.svg").default}/>
                </div>
            </div>
            <div className="wave-container">
                <div className="wave wave1"></div>
                <div className="wave wave2"></div>
                <div className="wave wave3"></div>
            </div>
        </div>
        </>
    )
}

export default CareerSection;