import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../providers/MouseMoveProvider';
import ApplystartButtonComponent from './ApplystartButtonComponent';

const AdBannerComponent = ({bgColor, callText, buttonText, link, external=true, iconRight=null, imgRight=null, iconLeft=null, imgLeft=null}) => {
    const { mouseDirection, mouseReverse } = useMouseMoveUI();
    return (
        <div className="edu-cta-banner-area home-one-cta-wrapper" 
        style={{
            backgroundImage:`url(${require("../assets/images/backgrounds/blogListBanner.jpg")})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            padding: "60px 0px"
            }}>
            <div className="container">
                <div className="edu-cta-banner">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800"
                            style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                                {callText}
                                {buttonText!==undefined?
                                <ApplystartButtonComponent text={buttonText} link={link} external={external} theme="primary" imgRight={imgRight} imgLeft={imgLeft} iconRight={iconRight} iconLeft={iconLeft} />
                                :null}
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group">
                        <motion.li className="shape-02 scene" style={{top:"-100px", left:"100px", opacity: "0.3"}}
                                   transition={{duration: 0}}
                            animate={ {
                                x: mouseDirection(30).x,
                                y: mouseDirection(30).y
                            } }
                        >
                            <img src={require("../assets/images/shapes/floatie-042.png")} alt="shape" />
                        </motion.li>
                        <motion.li className="shape-01 scene" style={{left:"60px"}}
                                    transition={{duration: 0}}
                            animate={ {
                                x: mouseReverse(30).x,
                                y: mouseReverse(30).y
                            } }
                        >
                            <img src={require("../assets/images/shapes/floatie-019.png")} alt="shape" />
                        </motion.li>
                        <motion.li className="shape-03 scene" style={{top:"50px", right:"100px"}}
                                   transition={{duration: 0}}
                            animate={ {
                                x: mouseDirection(30).x,
                                y: mouseDirection(30).y
                            } }
                        >
                            <img src={require("../assets/images/shapes/floatie-023.png")} alt="shape" />
                        </motion.li>
                        <motion.li className="shape-04 scene" style={{top:"-80px", right:"100px", opacity: "0.5"}}
                                   transition={{duration: 0}}
                            animate={ {
                                x: mouseReverse(30).x,
                                y: mouseReverse(30).y
                            } }
                        >
                            <img src={require("../assets/images/shapes/floatie-015.png")} alt="shape" />
                        </motion.li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AdBannerComponent;