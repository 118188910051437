import React from 'react';

const PaginationComponent = ({data, setPageIndex}) => {
    const pagination = data.meta.pagination;
    return (
        <>
            {pagination.page != 1 &&
                <li><a aria-label="Previous" onClick={()=>setPageIndex(pagination.page-1)}><i className="icon-west"></i></a></li>
            }

            {pagination.page - 3 > 0 &&
                <li className="more-next"><a onClick={()=>setPageIndex(pagination.page-3)}></a></li>
            }

            {pagination.page - 2 > 0 &&
                <li className=""><a onClick={()=>setPageIndex(pagination.page-2)}>{pagination.page - 2}</a></li>
            }

            {pagination.page - 1 > 0 &&
                <li className=""><a onClick={()=>setPageIndex(pagination.page-1)}>{pagination.page - 1}</a></li>
            }

            <li className="active"><a>{pagination.page}</a></li>

            {pagination.page + 1 < pagination.pageCount &&
                <li className=""><a onClick={()=>setPageIndex(pagination.page+1)}>{pagination.page + 1}</a></li>
            }

            {pagination.page + 2 < pagination.pageCount &&
                <li className=""><a onClick={()=>setPageIndex(pagination.page+2)}>{pagination.page + 2}</a></li>
            }
            {pagination.page + 3 < pagination.pageCount &&
                <li className="more-next" onClick={()=>setPageIndex(pagination.page+3)}><a></a></li>
            }

            {pagination.page != pagination.pageCount &&
                <li><a aria-label="Next" onClick={()=>setPageIndex(pagination.page+1)}><i className="icon-east"></i></a></li>
            }
            </>
    )
}

export default PaginationComponent;