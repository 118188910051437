import React, {useEffect, useState} from "react";
import {prepareImageUrl, sendRequestToCMS} from "../utils";
import {DATA_DEFAULTVALUES} from "../constants";
import AdBannerComponent from "../components/AdBannerComponent";
import sal from "sal.js";

export const TeamView = ()=>{
    const [team, setTeam] = useState(DATA_DEFAULTVALUES.NULL);

    useEffect(() => {
        sendRequestToCMS("/teams?sort[1]=order", setTeam);
    }, []);

    useEffect(()=>{
        // sal();
    },[team])


    return (
        <>
            <div className="edu-team-area team-area-2 pt-0 pb-5 mb-5">
                <div className="container min-vh-80">
                    <div className="section-title section-center" data-sal-delay="200" data-sal="fade" data-sal-duration="800">
                        <h3 className="title">The Team That Makes It Happen</h3>
                    <img src={require(`../assets/svgs/underline-primary.svg`).default} style={{height: "15px"}}/>
                    </div>

                    <div className="row g-5" >
                        {team?.data?.map((member) => {
                            return (
                              <>
                                  <div key={member.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                      <div className="edu-team-grid team-style-2" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                                          <div className="inner">
                                              <div className="thumbnail-wrap m">
                                                  <div className="thumbnail mb-0">
                                                      <img className={"rounded img-cover"} style={{height:"200px"}}  onLoad={(e)=>{sal();}}
                                                           src={prepareImageUrl(member?.attributes.image?.data?.attributes?.url)}  alt="Team Member" />
                                                  </div>
                                              </div>
                                              <span className="designation">{member?.attributes.name}</span>
                                              <div className="content">
                                                  <p className={"fs-3"} style={{lineHeight: "1.2", margin: "25px 0"}}>{member?.attributes.title}</p>
                                                  {member?.attributes.description!==null?<hr style={{borderTop: "0.5px solid var(--color-secondary)", opacity: "0.2", margin: "0 0 12px 0"}}/>:""}
                                                  <p className={"fs-4 pt-0 mt-0 pb-0"} style={{lineHeight: "1.5"}}>{member?.attributes.description}</p>

                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </>
                            )
                        })}
                    </div>
                </div>
            </div>
            <AdBannerComponent callText={<><h2>We're eager to help you!</h2><p>Book a Counselling Session With Us</p></>} buttonText={"View Available Sessions"} link={"https://linktr.ee/applystart"} iconRight="bi bi-arrow-up-right-square-fill"/>

        </>
    )
}