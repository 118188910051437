import {Link, useLocation} from "react-router-dom";
import React from "react";

export const MenuItemsSegment = ({menu_data}) => {

    const location = useLocation();

    return (
        <ul className="mainmenu text-secondary">
            {menu_data.map((menu, i) => (
                <li key={i} className={" "+ (menu.submenus?"has-droupdown ":"")+(location.pathname === menu.link?"hover-bg-secondary hover-text-tertiary":" hover-bg-secondary hover-text-tertiary ")}>
                    <Link className={"fw-200 w-100 fs-3  "+ (location.pathname === menu.link?" active ":"")} to={menu.link}>
                        {menu.title}
                    </Link>
                    {!menu.mega_menu &&
                        <ul className="submenu p-0 ">
                            {menu.submenus?.map((nav, i) => (
                                <li key={i} className={"p-0"}>
                                    {
                                        nav.external?
                                            <a href={`${nav.link}`} className={""} target={"_blank"} rel="noreferrer">
                                                {nav.title}
                                                {nav?.hot && <span className="badge-1">hot</span>}
                                                {nav?.new && <span className="badge">new</span>} {' '}
                                                <i className={"bi bi-box-arrow-up-right fs-3"}></i>
                                            </a>
                                            :
                                            <Link to={`${nav.link}`}>
                                                {nav.title}
                                                {nav?.hot && <span className="badge-1">hot</span>}
                                                {nav?.new && <span className="badge">new</span>}
                                            </Link>
                                    }

                                </li>
                            ))}
                        </ul>
                    }
                    {menu.mega_menu &&
                        <ul className="mega-menu">
                            {menu.submenus?.map((nav, i) => (
                                <li key={i}>
                                    <h6 className="menu-title">{nav.title}</h6>
                                    <ul className="submenu mega-sub-menu-01">
                                        {nav.mega_submenu.map((m, i) => (
                                            <li key={i}>
                                                <Link href={`${m.link}`}>
                                                    <a>{m.title}</a>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    }
                </li>
            ))}
        </ul>
    )
}