import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import React, {useEffect, useState} from "react";
import {DATA_DEFAULTVALUES} from "../constants";
import {prepareImageUrl, sendRequestToCMS} from "../utils";
import sal from "sal.js";

export const GalleryView = ()=>{
    const [gallery, setGallery] = useState(DATA_DEFAULTVALUES.NULL);
    useEffect(() => {
        sendRequestToCMS("/site-gallery", setGallery);
    }, []);

    useEffect(() => {
        sal();
    }, [gallery]);

    /*// photoIndex
    const [photoIndex, setPhotoIndex] = useState(null);
    // image open state
    const [open, setOpen] = useState(false);
    // handleImagePopup
    const handleImagePopup = (index) => {
        setPhotoIndex(index)
        setOpen(true)
    }*/



    return (
        <>
            <div className="edu-gallery-area min-vh-100 pt-4 pb-5 mb-5" style={{padding: '50px'}}>
                <div className="container">
                    <div id="masonry-gallery" className="gallery-grid-wrap">
                        <div className="section-title section-center" data-sal-delay="200" data-sal="fade" data-sal-duration="800">
                            <h3 className="title">Our Journey Through The Lens</h3>
                    <img src={require(`../assets/svgs/underline-primary.svg`).default} style={{height: "15px"}}/>
                        </div>
                        <div id="animated-thumbnials" className="edublink-react-gallery-grid">
                            <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 750: 2,992:3 }} >
                                <Masonry gutter="30px">
                                    {gallery?.data?.attributes.images.data?.map((img, i) => (
                                        <div key={i} /*onClick={() => handleImagePopup(i)}*/ data-sal-delay={"500"} data-sal="slide-down" data-sal-duration="800" className="edu-popup-image edu-gallery-grid w-100 masonry-item" /*style={{ cursor: 'pointer' }}*/>
                                            <div className="thumbnail">
                                                <img className='w-100 loading' src={prepareImageUrl(img.attributes.url)} alt="Gallery Image"  onLoad={(e)=>e.target.classList.remove('loading')}/>
                                            </div>
                                           {/* <div className="zoom-icon">
                                                <i className="icon-69"></i>
                                            </div>*/}
                                        </div>
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        </div>
                    </div>
                </div>
            </div>

           {/*  image light box start
            <ImageLightBox images={gallery_images} open={open} setOpen={setOpen}
                           photoIndex={photoIndex} setPhotoIndex={setPhotoIndex} />
             image light box end*/}
        </>
    )
}