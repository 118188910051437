
import React from "react";
import ApplystartButtonComponent from "../../components/ApplystartButtonComponent";

export const ButtonsList = () => {
    return (

        <>
            <div className="container fluid pt-5 pb-5" 
            >
                <p>The <b>&lt;ButtonCustom/&gt;</b> component is a generalized button compatible with bootstrap icons, as well as svg files. It accepts the following props: </p>

                <p className="p-0 m-0 text-primary"><b>text</b></p>
                <p className="p-0 m-0">Optional; if no text is provided, the button will render as a circular icon button</p>
                <span>"Apply now"</span><br/>
                <span>"Read more"</span><br/><br/>

                <p className="p-0 m-0 text-primary"><b>link</b></p>
                <span>"https://app.applystart.com/"</span><br/>
                <span>"/blogs"</span><br/><br/>

                <p className="p-0 m-0 text-primary"><b>external</b></p>
                <p className="p-0 m-0">Used to check if the link is external. It is false by default, hence can be used to navigate around the landing page. If the prop is passed as external=&#123;true&#125;, the link will be treated using an &lt;a&gt; tag. </p>
                <span>true</span><br/>
                <span>false</span><br/><br/>

                <p className="p-0 m-0 text-primary"><b>theme</b></p>
                <span>"primary"</span><br/>
                <span>"secondary"</span><br/>
                <span>"tertiary"</span><br/>
                <span>"primary hollow"</span><br/>
                <span>"secondary hollow"</span><br/>
                <span>"tertiary hollow"</span><br/>
                <span>"disabled"</span><br/><br/>

                <p className="p-0 m-0 text-primary"><b>iconLeft</b></p>
                <p className="p-0 m-0">OR</p>
                <p className="p-0 m-0 text-primary"><b>iconRight</b></p>
                <p className="p-0 m-0">(accepts bootstrap icon className="" classes)</p>
                <span>"icon-5"</span><br/>
                <span>"icon-6"</span><br/>
                <span>"bi bi-megaphone-fill"</span><br/>
                <span>and so on...</span><br/><br/><br/>

                <p className="p-0 m-0 text-primary"><b>imgLeft</b></p>
                <p className="p-0 m-0">OR</p>
                <p className="p-0 m-0 text-primary"><b>imgRight</b></p>
                <p className="p-0 m-0">(accepts image src="" links)</p>
                <span>"/applystart-a-logo.svg"</span><br/>
                <span>"/assets/images/applystart/canada/cardImg01.jpg"</span><br/>
                <span>and so on...</span><br/><br/>

                <hr style={{borderTop: "1px solid"}}/><br/>
                <p className="">Examples:</p>

                <ApplystartButtonComponent text="Apply now" link="https://app.applystart.com/" external={true} theme="primary" iconRight="icon-4"/>
                <p>&lt;ButtonCustom text=&quot;Apply now&quot; link="https://app.applystart.com/" external=&#123;true&#125; theme=&quot;primary&quot; iconRight=&quot;icon-4&quot; /&gt;</p>
                <ApplystartButtonComponent text="Apply now" link="https://app.applystart.com/" external={true} theme="primary hollow"/>
                <p>&lt;ButtonCustom text=&quot;Apply now&quot; link="https://app.applystart.com/" external=&#123;true&#125; theme=&quot;primary hollow&quot; /&gt;</p>
                <ApplystartButtonComponent text="Book a counseling session" link="/contact-us" theme="secondary"/>
                <p>&lt;ButtonCustom text=&quot;Book a counseling session&quot; link="/contact-us" theme=&quot;secondary&quot; /&gt;</p>
                <ApplystartButtonComponent text="Book a counseling session" link="/contact-us" theme="secondary hollow" imgLeft={"/applystart-a-logo.svg"}/>
                <p>&lt;ButtonCustom text=&quot;Book a counseling session&quot; link="/contact-us" theme=&quot;secondary hollow&quot; imgLeft=&quot;/applystart-a-logo.svg&quot; /&gt;</p>

                <div className="container fluid pt-5 pb-5"  hollow
                style={{backgroundColor:"#ff999c", padding:"50px"}}
                >
                    <ApplystartButtonComponent text="download" link="/blogs" theme="tertiary" iconRight={"bi bi-megaphone-fill"}/>
                    <p>&lt;ButtonCustom text=&quot;Apply now&quot; link="/blogs" theme=&quot;tertiary&quot; iconRight=&quot;bi bi-megaphone-fill&quot; /&gt;</p>
                    <ApplystartButtonComponent text="download" link="/blogs" theme="tertiary hollow" iconLeft={"bi bi-megaphone-fill"}/>
                    <p>&lt;ButtonCustom text=&quot;Apply now&quot; link="/blogs" theme=&quot;tertiary hollow&quot; iconLeft=&quot;bi bi-megaphone-fill&quot; /&gt;</p>
                </div>

                <br/>
                <ApplystartButtonComponent text="Apply now" link="/blogs" theme="disabled" iconRight={"icon-6"}/>
                <p>&lt;ButtonCustom text=&quot;Apply now&quot; link="/blogs" theme=&quot;disabled&quot; iconRight=&quot;icon-6&quot; /&gt;</p>

                <ApplystartButtonComponent link="https://app.applystart.com/" external={true} theme="primary" iconRight="icon-4"/>
                <p>&lt;ButtonCustom link="https://app.applystart.com/" external=&#123;true&#125; theme=&quot;primary&quot; iconRight=&quot;icon-4&quot; /&gt;</p>


                <hr style={{borderTop: "1px solid"}}/><br/>
                <h3>Usage without the component:</h3>
                <p className="">In order to use this button in forms, you may use it directly (without calling the &lt;ButtonCustom/&gt; component) by adding the className "button-custom" followed by the styles listed above.</p>
                <p className="">However, this will miss out on the feature of adding icons to buttons like we did using the component.</p>
                <button type="submit" className="button-custom primary">Submit</button>
                <p>&lt;button type="submit" className="button-custom primary"&gt;Submit&lt;/button&gt;</p>
                <button className="button-custom secondary hollow">Get started</button>
                <p>&lt;button className="button-custom secondary hollow"&gt;Get started&lt;/button&gt;</p>
                <button className="button-custom disabled">Download</button>
                <p>&lt;button type="submit" className="button-custom disabled"&gt;Download&lt;/button&gt;</p>

            </div>
        </>
    )
}