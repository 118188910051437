import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../../providers/MouseMoveProvider';
import React from "react";

const SubHeroSection = (props) => {
    const { mouseDirection, mouseReverse } = useMouseMoveUI();
    return (
        <div className="hero-banner hero-style-1" style={{backgroundImage:`url(${props.bgImg})`, paddingTop:"60px"}}>
            <div className="container">
                <div className="row">
                    <h1 className={props.className}
                        data-sal-delay="100" data-sal="slide-up" data-sal-duration="1000"
                        style={{textAlign:"start"}}
                    >
                        {props.title}
                    </h1>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6 order-lg-2">
                        <div className="banner-content">
                            <props.content/>
                            <ul className="shape-group">
                                <motion.li className="shape-1 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000"
                                           style={{left:"400px", top: "-150px"}}
                                           transition={{duration: 0}} animate={ {
                                    x: mouseReverse(25).x,
                                    y: mouseReverse(25).y
                                } }
                                >
                                    <img src={require(`../../assets/images/shapes/floatie-${props.floaties[0]}.png`)} alt="Shape" />
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-1">
                        <div className="banner-thumbnail">
                            <div className="thumbnail" data-sal-delay="150" data-sal="slide-right" data-sal-duration="1000">
                                <img src={props.img} alt="Country Flag" />
                            </div>
                            <ul className="shape-group">
                                <motion.li className="shape-5 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000"
                                           style={{left:"400px"}}
                                           transition={{duration: 0}} animate={ {
                                    x: mouseReverse(25).x,
                                    y: mouseReverse(25).y
                                } }
                                >
                                    <img src={require(`../../assets/images/shapes/floatie-${props.floaties[1]}.png`)} alt="Shape" />
                                </motion.li>
                                <motion.li className="shape-6 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000"
                                           style={{right:"400px"}}
                                           transition={{duration: 0}} animate={ {
                                    x: mouseDirection(20).x,
                                    y: mouseDirection(20).y
                                } }
                                >
                                    <img src={require(`../../assets/images/shapes/floatie-${props.floaties[2]}.png`)} alt="Shape" />
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubHeroSection;