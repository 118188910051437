import React from 'react';
import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../../providers/MouseMoveProvider';



const BenefitsSection = (props) => {

    const { mouseDirection, mouseReverse } = useMouseMoveUI();
    return (
        <div className="edu-campus-area" 
        // style={{backgroundColor:"#404040", padding:"-50px"}}
        >
            <div className="container edublink-animated-shape" style={{transform:"translate(0,-100px)"}}>
                <div className="row g-5">
                    <div className="col-xl-7"  style={{zIndex:"100"}} data-sal-delay="50" data-sal="slide-right" data-sal-duration="800">
                        <div className="campus-image-gallery">
                            <div className="campus-thumbnail">
                                <div className="thumbnail">
                                    <h1 className="bannerBlackWhite"  style={{textAlign:"start"}}>{props.title}</ h1>
                                </div>
                            </div>
                            <ul className="shape-group">
                                <motion.li className="shape-1 scene" style={{top: "-100px", left: "-110px"}}
                                    transition={{duration: 0}} animate={ {
                                        x: mouseDirection(30).x,
                                        y: mouseDirection(30).y
                                    } }
                                >
                                    <span></span>
                                </motion.li>
                                <motion.li className="shape-2 scene"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseReverse(25).x,
                                        y: mouseReverse(25).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-102.png")} alt="Shape" />
                                </motion.li>
                                <motion.li className="shape-3 scene"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseDirection(25).x,
                                        y: mouseDirection(25).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-040b.png")} alt="Shape" />
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-5 mt-0 pt-0 mb-0 pb-0" data-sal-delay="50" data-sal="slide-left" data-sal-duration="800">
                        <div className="campus-content">
                            <div className="inner">
                                {/* <div className="section-title section-left">
                                    <span className="pre-title">Campus</span>
                                    <h2 className="title">Campus Life</h2>
                                    <span className="shape-line">
                                        <i className="icon-19"></i>
                                    </span>
                                </div> */}
                                <div className="features-list"> 
                                    {props.items.map((item,i) => {
                                        const {color,icon,subtitle,title} = item;
                                        return (
                                            <div key={i} className={`features-box`}>
                                                <img className="icon" src={require(`../../assets/svgs/${icon}`)} alt="icon"/>
                                                <div>
                                                    <h3 className="title mb-0">{title}</h3>
                                                    <p className="mb-0">{subtitle}</p>
                                                </div>
                                            </div> 
                                        )
                                    })} 
                                </div>
                            </div>
                            <ul className="shape-group">
                                <motion.li className="shape-4 scene"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseReverse(25).x,
                                        y: mouseReverse(25).y
                                    } }
                                >
                                    <span></span>
                                </motion.li>
                                <motion.li className="shape-5 scene"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseDirection(30).x,
                                        y: mouseDirection(30).y
                                    } }
                                >
                                    <span></span>
                                </motion.li>
                                <motion.li className="shape-6 scene"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseReverse(30).x,
                                        y: mouseReverse(30).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-019.png")} alt="Shape" />
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BenefitsSection;