import React, { useEffect, useState } from 'react';
import HorizontalCarouselComponent from '../../components/HorizontalCarouselComponent.js';
import { DATA_DEFAULTVALUES } from '../../constants.js';
import {prepareImageUrl, sendRequestToCMS} from '../../utils.js';
import sal from 'sal.js';

export const ConsultantsSection = () => {
    const [consultants,setConsultants] = useState(DATA_DEFAULTVALUES.NULL);

    useEffect(() => {
        sendRequestToCMS(`/consultants`, setConsultants);
    }, []);

    useEffect(() => {
        sal();
    }, [consultants]);

    if(consultants===DATA_DEFAULTVALUES.NULL) return <p>loading</p>;
    else{
        return (
            <div style={{backgroundImage:`url(${require("../../assets/images/backgrounds/bg5.jpg")})`, zIndex:"-1000"}} id={"consultantsList"}>
                <div className="edu-course-area course-area-6 edu-section-gap mb-0">
                    <div className="container mb-0">
                        <div className="section-title section-center mb-0" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <h1 className="bannerWhiteBlack">Find Consultants</h1>
                            <p style={{color:"#fff"}}>Our team of consultants is made of <b>international students who have been in your shoes.</b></p>
                    <img src={require(`../../assets/svgs/underline-tertiary.svg`).default} style={{height: "15px"}}/>
                        </div>
                        {
                            consultants && consultants===DATA_DEFAULTVALUES.NULL? null :
                            <HorizontalCarouselComponent component={ConsultantCard} items={consultants} />
                        }
                    </div>
                </div>
                <div class="wave-container">
                    <div class="wave wave1"></div>
                    <div class="wave wave2"></div>
                    <div class="wave wave3"></div>
                </div>
            </div>
        )
    }

}

const ConsultantCard = (props) => {

    const consultant = props.item;

    return (
        <div className="consultantCard">
            {prepareImageUrl(consultant.attributes.image?.data?.attributes?.url)=="https://cms.applystart.comundefined"?
                <img src={require(`../../assets/svgs/consultantDefault.svg`).default} alt={consultant.attributes.name}/>
                :<img src={prepareImageUrl(consultant.attributes.image?.data?.attributes?.url)} alt={consultant.attributes.name}/>}
            <h1>{consultant.attributes.name}</h1>
            <p>{consultant.attributes.designation}</p>
            <div className="consultantLinksContainer">
                {consultant.attributes.email?<a href={`mailto:${consultant.attributes.email}`} target="_blank" className="consultantLink email" rel="noreferrer"></a>:null}
                {consultant.attributes.facebook?<a href={consultant.attributes.facebook} target="_blank" className="consultantLink facebook" rel="noreferrer"></a>:null}
                {consultant.attributes.instagram?<a href={consultant.attributes.instagram} target="_blank" className="consultantLink instagram" rel="noreferrer"></a>:null}
                {consultant.attributes.linkedIn?<a href={consultant.attributes.linkedIn} target="_blank" className="consultantLink linkedIn" rel="noreferrer"></a>:null}
            </div>
        </div>
    )
}