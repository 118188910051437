import { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import button from "bootstrap/js/src/button";


export const ApplystartButtonComponent = (props) => {

    const [margin, setMargin] = useState("10px");

    useEffect( () => {
        if (props.text===undefined) {setMargin("0px");}
    }, [] );

    return (
        <>
            {props.external && props.link?
                <a href={props.link} target="_blank" rel={"noreferrer"} className={`button-custom ${props.text===undefined?"circle":""} ${props.theme} `+props.className}>
                    {props.iconLeft?<i className={props.iconLeft} style={{marginRight:margin}}></i>:null}
                    {props.imgLeft?<img src={props.imgLeft} style={{marginRight:margin}}/>:null}
                    {props.text??null}
                    {props.iconRight?<i className={props.iconRight} style={{marginLeft:margin}}></i>:null}
                    {props.imgRight?<img src={props.imgRight} style={{marginLeft:margin}}/>:null}
                </a>
                :
                props.link?
                <Link to={props.link} className={`button-custom ${props.text===undefined?"circle":""} ${props.theme} `+props.className}>
                    {props.iconLeft?<i className={props.iconLeft} style={{marginRight:margin}}></i>:null}
                    {props.imgLeft?<img src={props.imgLeft} style={{marginRight:margin}}/>:null}
                    {props.text??null}
                    {props.iconRight?<i className={props.iconRight} style={{marginLeft:margin}}></i>:null}
                    {props.imgRight?<img src={props.imgRight} style={{marginLeft:margin}}/>:null}
                </Link>
                    :
                    <button
                        onClick={props.onClick}
                        className={`button-custom ${props.text===undefined?"circle":""} ${props.theme} `+props.className}>
                        {props.iconLeft?<i className={props.iconLeft} style={{marginRight:margin}}></i>:null}
                        {props.imgLeft?<img src={props.imgLeft} style={{marginRight:margin}}/>:null}
                        {props.text??null}
                        {props.iconRight?<i className={props.iconRight} style={{marginLeft:margin}}></i>:null}
                        {props.imgRight?<img src={props.imgRight} style={{marginLeft:margin}}/>:null}
                    </button>
            }
        </>
        
    )
}

export default ApplystartButtonComponent;