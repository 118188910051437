import ApplystartButtonComponent from "../../components/ApplystartButtonComponent";
import { motion } from "framer-motion";
import { useMouseMoveUI } from "../../providers/MouseMoveProvider";
import {useEffect, useState} from "react";
import { DATA_DEFAULTVALUES } from "../../constants";
import { sendRequestToCMS } from "../../utils";
import { prepareImageUrl } from "../../utils";
import sal from 'sal.js';

export const TeamSection = () => {
    const [gallery, setGallery] = useState(DATA_DEFAULTVALUES.NULL);
    const [images, setImages] = useState([]);

    useEffect(() => {
        sendRequestToCMS("/site-gallery", setGallery);
    }, []);

    useEffect(() => {
        sal();
        setImages(
            gallery?.data?.attributes.images.data
                ? [...gallery.data.attributes.images.data]
                    .sort(() => Math.random() - 0.5)
                    .map((img) => prepareImageUrl(img.attributes.formats.thumbnail.url+"?width=150&height=150"))
                : []
            );
    }, [gallery]);

    const { mouseDirection, mouseReverse } = useMouseMoveUI();

    return (
        <div className="edu-brand-area brand-area-1 gap-top-equal team-container" style={{zIndex:"150 !important"}}>
            <div className="container edublink-animated-shape" style={{zIndex:"150 !important"}}>
                <div className="row team-row">
                    <div className="col-lg-8 order-lg-2 mb-5 team-icons-container" data-sal="slide-up" data-sal-delay="150" data-sal-duration="300">
                        {images.slice(0, 8).map((b, i) => (
                            <img src={b} alt="Team member"/>
                        ))}
                        <img src={images[8]} className="team-icon-9" alt="Team member"/>
                        <img src={images[9]} className="team-icon-10" alt="Team member"/>
                    </div>
                    <div className="col-lg-4 order-lg-1 mb-5 team-text-container">
                        <div className="" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <h2 className="black">Meet the Team that Makes it Happen</h2>
                            <ApplystartButtonComponent text="Team Applystart" link="/team" theme="secondary hollow" imgLeft={require("../../assets/brand/logo-symbol-red.svg").default} />
                            <ApplystartButtonComponent text="Gallery" link="/gallery" theme="secondary mt-2" iconLeft="bi bi-camera-fill"/>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group">
                    <motion.li className="shape-1 scene"
                        style={{top:"350px", right:"150px", zIndex:"50 !important"}} transition={{duration: 0}} animate={ {
                            x: mouseDirection(30).x,
                            y: mouseDirection(30).y
                        } }
                    >
                        <img src={require("../../assets/images/shapes/floatie-003.png")} alt="Shape" />
                    </motion.li>
                    <motion.li className="shape-2 scene"
                        style={{top:"150px",left:"150px",scale:"1", opacity:"0.2"}} transition={{duration: 0}} animate={ {
                            x: mouseReverse(25).x,
                            y: mouseDirection(25).y
                        } }
                    >
                        <img src={require("../../assets/images/shapes/floatie-048e.png")} alt="Shape" />
                    </motion.li>
                    <motion.li className="shape-3 scene"
                        style={{top:"250px",left:"350px"}} transition={{duration: 0}} animate={ {
                            x: mouseReverse(25).x,
                            y: mouseReverse(25).y
                        } }
                    >
                        <img src={require("../../assets/images/shapes/floatie-104.png")} alt="Shape" />
                    </motion.li>
                    <motion.li className="shape-6 scene"
                        style={{top:"0px",left:"200px", scale:"1", zIndex:"50 !important"}} transition={{duration: 0}} animate={ {
                            x: mouseDirection(30).x,
                            y: mouseDirection(30).y
                        } }
                    >
                        <img src={require("../../assets/images/shapes/floatie-064.png")} alt="Shape" />
                    </motion.li>
                </ul>
        </div>
    )
}