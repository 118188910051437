import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {DATA_DEFAULTVALUES} from "../../constants";
import {prepareImageUrl, sendRequestToCMS} from "../../utils";
import ApplystartButtonComponent from '../../components/ApplystartButtonComponent';
import sal from "sal.js";
const BlogSidebarSection = () => {

    const [allBlogs, setAllBlogs] = useState(DATA_DEFAULTVALUES.NULL);
    const [blogsToShow, setBlogsToShow] = useState(DATA_DEFAULTVALUES.NULL);

    useEffect(() => {
        sendRequestToCMS(`/blogs?fields[0]=title&fields[1]=description&fields[2]=publishDate&fields[3]=slug&fields[4]=keywords&sort[0]=publishDate:desc&pagination[pageSize]=300`, setAllBlogs);

    }, []);

    useEffect(() => {
        sal();
    }, [allBlogs]);

    const showLatestBlogs = ()=>{
        if(!allBlogs?.data)
            return;
        const _blogs = allBlogs.data.slice(0,5);
        setBlogsToShow(_blogs);
    }
    const updateSearch = (e)=>{
        if(allBlogs == null || allBlogs.data == null || allBlogs.data.count === 0 || !e.target.value || e.target.value.trim() === "") {
            showLatestBlogs();
            return;
        }
        const _blogs = allBlogs.data.filter(x=> x.attributes.title.toLowerCase().includes(e.target.value?.toLowerCase())
            || x.attributes.keywords?.toLowerCase().includes(e.target.value?.toLowerCase())
        );
        setBlogsToShow(_blogs);
    }

    useEffect(() => {
        showLatestBlogs();
    }, [allBlogs]);

    useEffect(()=>{sal()},[blogsToShow]);

    return (
        <div className="edu-blog-sidebar p-0" data-sal="slide-left" data-sal-duration="800">
            <div className="edu-blog-widget widget-search">
                <div className="inner">
                    <h4 className="widget-title mb-4">Find Blogs</h4>
                    <div className="content">
                        <form className="blog-search" onSubmit={e => e.preventDefault()}>
                            <button className="search-button"><i className="bi bi-search"></i></button>
                            <input type="text" placeholder="Search" onChange={updateSearch}/>
                        </form>
                    </div>
                </div>
            </div>

            <div className="edu-blog-widget widget-latest-post min-vh-60">
                <div className="inner">
                    <h4>Latest Post</h4>
                        {blogsToShow?.map((blog) => (
                            <div key={blog.attributes.id} data-sal={"fade"} data-sal-duration={"800"}>
                                <Link to={`/blogs/${blog.attributes.slug}`} className="blog-sidebar-latest-post" >
                                    <img className={"loading"} onLoad={(e)=>e.target.classList.remove('loading')} src={prepareImageUrl(blog.attributes.image.data.attributes.url)} width={100} alt="Blog Images" />
                                    <div>
                                        <h1 className="overflow-hidden truncate-two-lines">{blog.attributes.title}</h1>
                                        <div className={"blog-sidebar-date"}>
                                            <i className="bi bi-calendar-plus"></i>
                                            <p>{new Date(blog.attributes.publishDate)?.toDateString()}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
            </div>

            <div className="edu-blog-widget widget-action pt-0">
                <div style={{display: "flex", flexDirection:"column", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                    <h4 className="title">Looking for a different topic? {/*<span>EduBlink</span>*/}</h4>
                    <span className="shape-line"><i className="icon-19"></i></span>
                    <p style={{fontSize:"1.6rem",textAlign:"start"}}>We can help! Just send us information regarding your concerns about studying abroad. We will research and present for you.</p>
                    <ApplystartButtonComponent text="Request Now" link="https://docs.google.com/forms/d/e/1FAIpQLSdG_CcViqmFU5glsCJ7JJHIOvB8i3GrG11YSddxyWghQAKe8Q/viewform" external={true} theme="secondary" iconRight="bi bi-arrow-up-right-square-fill" />
                </div>
            </div>
        </div>
    )
}

export default BlogSidebarSection;