import React from 'react';
import { motion } from 'framer-motion';
import { useMouseMoveUI } from '../../providers/MouseMoveProvider';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import {CONTACT_DATA} from "../../constants";

const CTASection = () => {
    const { mouseDirection, mouseReverse } = useMouseMoveUI();
    return (
        <div className="cta-area-1">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <div className="home-four-cta edu-cta-box cta-style-3 contact-us-cta">
                            <div className="inner">
                                <div className="cta-get-in-touch">
                                    <span>Get In Touch:</span>
                                    <h3>
                                        <Link to="/contact-us">team@applystart.com</Link>
                                    </h3>
                                </div>
                                <div className="sparator">
                                    <span style={{color:'var(--color-secondary)'}}>or</span>
                                </div>
                                <div className="cta-whatsapp">
                                    <span>Knock us on WhatsApp:</span>
                                    <h3>
                                        <a href={`https://wa.me/${CONTACT_DATA.WHATSAPP}`} rel="noreferrer" target="_blank">+880 1862 361 386</a>
                                    </h3>
                                </div>
                            </div>
                            <ul className="shape-group">
                                <motion.li className="shape-01 scene"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseReverse(25).x,
                                        y: mouseReverse(25).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/floatie-021.png")} alt="shape" />
                                </motion.li>
                                <motion.li className="shape-02 scene"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseDirection(25).x,
                                        y: mouseDirection(25).y
                                    } }
                                >
                                    <img src={require("../../assets/images/shapes/edu-shape-12.png")} alt="shape" />
                                </motion.li>
                                <motion.li className="shape-03 scene"
                                    transition={{duration: 0}} animate={ {
                                        x: mouseDirection(25).x,
                                        y: mouseDirection(25).y
                                    } } style={{top: '50px'}}
                                >
                                    <img src={require("../../assets/images/shapes/floatie-017.png")} alt="shape" />
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CTASection;