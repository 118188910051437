// vendor styles
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'swiper/swiper-bundle.min.css'

// import 'react-18-image-lightbox/style.css';

// applystart-managed styles
import './assets/styles/app.scss'


import {Routes} from "./Routes";
import React, {useEffect} from "react";
import {MouseMoveProvider} from "./providers/MouseMoveProvider";
import sal from 'sal.js';
import {CurtainComponent} from "./components/layout/CurtainComponent";
import ScrollToTopComponent from "./components/layout/ScrollToTopComponent";
import {BrowserRouter} from "react-router-dom";
import AppProvider from "./providers/AppProvider";

function App() {
    useEffect(() => {
        sal({threshold: 0.2, once: true});
    }, []);

    return (
        <>
            <CurtainComponent/>
            <div className="App">
                <ScrollToTopComponent/>
                <BrowserRouter>
                    <MouseMoveProvider>
                        <AppProvider>
                            <Routes/>
                        </AppProvider>
                    </MouseMoveProvider>
                </BrowserRouter>
            </div>
        </>
    );
}

export default App;
