import React, {useEffect, useState} from "react";
import {DATA_DEFAULTVALUES} from "../constants";
import {sendRequestToCMS} from "../utils";
import FaqItemComponent from "../components/FaqItemComponent";
import {LoadingComponent} from "../components/layout/LoadingComponent";
import ApplystartButtonComponent from "../components/ApplystartButtonComponent";

export const FaqView = ()=> {
    const [FAQs, setFAQs] = useState(DATA_DEFAULTVALUES.NULL);
    const [activeNavTab, setActiveNav] = useState(0);

    useEffect(() => {
        sendRequestToCMS("/faqs?filters[faqTopic][$contains]=site-", setFAQs);
    }, []);

    const topics = [
        {title: "Questions about Applystart", slug:"site-company"},
        {title: "General Questions", slug:"site-general"},
    ]
    if (FAQs === DATA_DEFAULTVALUES.NULL)
        return <div className="min-vh-70"/>

    return (
        <section className="faq-page-area min-vh-70">
            <div className="container mb-5 pb-5">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="faq-page-nav">
                            <h3 className="title">Questions By This Category</h3>
                            <p>Gain knowledge from the countless answers to questions asked by other students who have been in your shoes. Browse questions about us, or explore the topics that pop up most frequently.</p>

                            <ul className="nav nav-tabs" role="tablist">
                                {
                                    topics.map((topic, index) =>
                                        <li className="nav-item" role="presentation">
                                            <ApplystartButtonComponent className={"border-none shadow-none bg-none hover-scale "+(index === activeNavTab?"text-primary":"")}
                                                                       text={topic.title} imgLeft={index === activeNavTab && require("../assets/svgs/rightArrow.svg").default}
                                                                       onClick={()=>setActiveNav(index)}
                                            />
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="tab-content faq-page-tab-content"  id="faq-accordion">
                            {
                                topics.map((topic,tIndex)=>
                                    tIndex===activeNavTab &&
                                    <div className={"tab-pane fade "+ (tIndex===activeNavTab?"show active":"")} id={topic.slug} role="tabpanel">
                                        <div className="faq-accordion">
                                            <div className="accordion">
                                                {FAQs?.data == null && <LoadingComponent/>}
                                                {FAQs?.data.filter(x=>x.attributes.faqTopic === topic.slug).map((item, index)=>
                                                    <FaqItemComponent show={index===0} id={index} title={item.attributes.question} desc={item.attributes.answer} />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}