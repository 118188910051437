import React, {useEffect, useState} from "react";
import ApplystartButtonComponent from '../../components/ApplystartButtonComponent';

export const ApplicationStepsSection = () => {
    const [step, setStep] = useState(1);
    const [phoneScroll, setPhoneScroll] = useState(-280);
    const [laptopScroll, setLaptopScroll] = useState(-840);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [prevDisabled, setPrevDisabled] = useState(true);

    useEffect(() => {
        if(step === 5) {
            setNextDisabled(true);
        }
        else if (step === 1) {
            setPrevDisabled(true);
        }
        else{
            setPrevDisabled(false);
            setNextDisabled(false);
        }
    }, [step]);

    const nextStep = () => {
        if(step === 5) return;
        setStep(step+1);
        setPhoneScroll(phoneScroll-280);
        setLaptopScroll(laptopScroll-840);

    }

    const previousStep = () => {
        if(step === 1) return;
        setStep(step-1);
        setPhoneScroll(phoneScroll+280);
        setLaptopScroll(laptopScroll+840);
    }

    return (
        <>
            <div style={{backgroundImage:`url(${require("../../assets/images/backgrounds/bg7.jpg")})`, backgroundSize:"cover", paddingTop:"60px", paddingBottom:"30px"}}>
                <div style={{backgroundImage:`url(${require("../../assets/brand/logo-symbol-red.svg").default})`, backgroundSize:"contain", backgroundPosition:"center", backgroundRepeat:"no-repeat"}}>
                {/*<div >*/}
                    <div className="container fluid">
                        <h1 className='title bannerWhiteBlack apply-steps-title'>How Applystart Is Transforming Your Application Experience</h1><br/>
                    </div>
                    <div className="apply-steps-section">
                        <img src={require(`../../assets/svgs/iconArrowLeftSecondary.svg`).default} className={`step-control-btn desktop ${prevDisabled?'disabled':""}`} onClick={()=>{previousStep()}}/>
                        <div className="phone-container" style={{ "--left": `${phoneScroll}px` }}>
                            <div className="phone" 
                            style={{ 
                                "--phoneProgressBar": `url('/assets/images/applystart/landing/phoneProgress0${step}.png')`, 
                                "--phone-screen": `url('/assets/images/applystart/landing/phoneStep0${step}.png')`
                                }}></div>
                        </div>
                        <div className="laptop-container" style={{ "--left": `${laptopScroll}px` }}>
                            <div className="laptop" 
                            style={{ 
                                "--phoneProgressBar": `url('/assets/images/applystart/landing/phoneProgress0${step}.png')`,
                                "--laptop-screen": `url('/assets/images/applystart/landing/laptopStep0${step}.png')`
                                }}></div>
                        </div>
                        <img src={require(`../../assets/svgs/iconArrowRightSecondary.svg`).default} className={`step-control-btn desktop ${nextDisabled?'disabled':""}`} onClick={()=>{nextStep()}}/>
                    </div>
                    <div className="container fluid">
                        <div className='mobile-controls'>
                            <img src={require(`../../assets/svgs/iconArrowLeftSecondary.svg`).default} className={`step-control-btn ${prevDisabled?'disabled':""}`} onClick={()=>{previousStep()}}/>
                            <img src={require(`../../assets/svgs/iconArrowRightSecondary.svg`).default} className={`step-control-btn ${nextDisabled?'disabled':""}`} onClick={()=>{nextStep()}}/>
                        </div>
                        <div className="row justify-content-center p-5">
                        <ApplystartButtonComponent text="Get started" link="https://app.applystart.com/" external={true} theme="tertiary hollow" />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}