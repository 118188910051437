import React from "react";
import HeroSection from "./HeroSection";
import WhyChooseSection from "./WhyChooseSection";
import CostsSection from "./CostsSection";
import BenefitsSection from "./BenefitsSection";
import TestimonialsSection from "./TestimonialsSection";
import CanadaSubSection from "./CanadaSubSection";
import {DocumentChecklistSection} from "./DocumentChecklistSection";
import {FaqSection} from "../FaqSection";
import {Helmet} from "react-helmet";
import {ConsultantsSection} from "./ConsultantsSection";


export const CanadaView = () => {

    // WHY CHOOSE CANADA
    const wccItems = [
        {
            image: require("../../assets/images/views/canadaView/cardImg01.jpg"),
            title: "Globally recognized qualifications"
        },
        {image: require("../../assets/images/views/canadaView/cardImg02.jpg"), title: "Affordable costs"},
        {image: require("../../assets/images/views/canadaView/cardImg03.jpg"), title: 'Work during study'},
        {image: require("../../assets/images/views/canadaView/cardImg04.jpg"), title: "Pro-Immigration Policies"},
        {
            image: require("../../assets/images/views/canadaView/cardImg05.jpg"),
            title: "Globalised Economy driven by technological innovations"
        },
        {
            image: require("../../assets/images/views/canadaView/cardImg06.jpg"),
            title: "Welcoming and progressive community"
        },
    ];

    // COSTS OF STUDYING IN CANADA
    const costItems = [
        {
            title: "Tuition Fees",
            text: `Tuition fees for international students averaged <b>$32,019</b> annually in 2020/21 for undergraduate students, while graduate tuition costs averaged <b>$19,252</b> annually. `
        },
        {
            title: "Living Costs",
            text: `Living costs may vary between <b>10,000 CAD to 15,000 CAD</b> annually for difference provinces and lifestyle of student. `
        }];

    // BENEFITS OF USING APPLYSTART
    const benefitItems = [
        {
            icon: 'iconCheckmark.svg',
            title: "Easier",
            subtitle: "One platform with all the programs and its requirements from 200+ universities and colleges in Canada"
        },
        {
            icon: 'iconDollar.svg',
            title: "Cheaper",
            subtitle: "One platform with all the programs and its requirements from 200+ universities and colleges in Canada"
        },
        {
            icon: 'iconStopwatch.svg',
            title: "Faster",
            subtitle: "One platform with all the programs and its requirements from 200+ universities and colleges in Canada"
        },
        {
            icon: 'iconTarget.svg',
            title: "Accurate",
            subtitle: "One platform with all the programs and its requirements from 200+ universities and colleges in Canada"
        }

    ]

    return (

        <>
            <Helmet>
                <title>{"Study in Canada: Apply for top universities in Canada | Applystart"}</title>
                <meta name={"description"}
                      content={"Discover the benefits of studying in Canada, explore study programs, costs, and expert consultants. Start your journey to higher education here with Applystart."}/>
                <meta property={"og:title"}
                      content={"Study in Canada: Apply for top universities in Canada | Applystart"}/>
                <meta property={"og:description"}
                      content={"Discover the benefits of studying in Canada, explore study programs, costs, and expert consultants. Start your journey to higher education here with Applystart."}/>
            </Helmet>

            <HeroSection
                backgroundImage={require("../../assets/images/views/canadaView/canadaBannerB.jpg")}
                flag={require("../../assets/images/views/canadaView/canadaBannerA.png")}
                heading={"Study in Canada"}
                headingStyle={"bannerRedWhite"}
                subHeading={"Find Your Gateway to Excellence with Applystart"}
                caption={"'Study in Canada' is emerging as a premier choice for international students, transforming into a land with opportunities to live, learn and grow in, more than just a place for education."}
                buttonText={"APPLY NOW"}
                floaties={["003b", "100", "012"]}
            />

            <WhyChooseSection
                title={"Why Studying in Canada is the Best?"}
                subtitle={"Top Reasons to Study in Canada:"}
                caption={"In 2022, Canada hosted over 807,500 international students (17.2% of total higher education student population globally). According to the Canadian Bureau for International Education (CBIE), 86.8% of international students recommend Canada as a study destination."}
                items={wccItems}
            />

            {/* <PopularInstitutions/> */}

            <CostsSection
                title={"Cost of Studying in Canada"}
                subtitle={'<b>"Study in Canada" Costs</b> can be broken into two chunks: Tuition fees and Living costs. '}
                caption={"Canadian educational institutions have multiple scholarship opportunities, from general Entrance Scholarships to competitive scholarship programs like Lester B. Pearson and International Leader of Tomorrow Award (ILOt). These aids coupled with the access to part-time work helps to alleviate some of the financial burden for international students."}
                items={costItems}
            />

            <ConsultantsSection/>

            <BenefitsSection style={{transform: "translate(0,-200px)"}}
                             title={"With Applystart, the process to study in Canada is now —"}
                             items={benefitItems}
            />

            <TestimonialsSection testimonialTopic={"testimonialsCanada"}/>

            <CanadaSubSection/>

            <DocumentChecklistSection
                source={"canadaDocumentChecklist"}
                file={require("../../assets/pdfs/documentChecklist-canada.pdf")}
                name={"Document Checklist for Canada"}
            />

            <hr style={{borderTop: "1px dashed red"}}/>
            <br/>

            <FaqSection faqTopic={"canada"}/>
        </>
    )
}