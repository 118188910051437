import ApplystartButtonComponent from "../../components/ApplystartButtonComponent";
import { SmallBlogCardComponent } from "../../components/SmallBlogCardComponent";
import { useState, useEffect } from "react";
import { DATA_DEFAULTVALUES } from "../../constants";
import { sendRequestToCMS } from "../../utils";
import sal from 'sal.js';

const WisdomCornerSection = (props) => {
    const [blogs,setBlogs] = useState(DATA_DEFAULTVALUES.NULL);

    useEffect(() => {
        sendRequestToCMS(`/blogs`, setBlogs);
    }, []);

    useEffect(() => {
        sal();
    }, [blogs]);

    return (
        <div className="container fluid mt-0 pt-0">
            <div className="row">
                <h2>Wisdom Corner for Starters</h2>
                {(blogs !== DATA_DEFAULTVALUES.NULL && blogs.data.length > 0)?
                    <SmallBlogCardComponent blog={blogs.data[blogs.data.length-1]}/> : null}
            </div>
            <div className="row">
                <div className="col-lg-6">
                    {(blogs !== DATA_DEFAULTVALUES.NULL && blogs.data.length > 0)?
                        blogs.data.slice(blogs.data.length-4, blogs.data.length-1).map(blog =>
                            <SmallBlogCardComponent blog={blog}/>
                    ) : null}
                </div>
                <div className="col-lg-6">
                    {(blogs !== DATA_DEFAULTVALUES.NULL && blogs.data.length > 0)?
                        blogs.data.slice(blogs.data.length-7, blogs.data.length-4).map(blog =>
                            <SmallBlogCardComponent blog={blog}/>
                    ) : null}
                </div>
            </div>
            <br/>
            <div className="row justify-content-center mb-5 pb-5">
                <ApplystartButtonComponent text="Read more blogs" link="/blogs" theme="primary" iconLeft="bi bi-book-half"/>
            </div>
        </div>
    )
}

export default WisdomCornerSection;